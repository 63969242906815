import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";


import { SharedModule } from "./shared/shared.module";
import { AuthenticationService } from "./_services/authentication.service";
import { DashboardService } from "./_services/dashboard.service";
import { LoansService } from "./_services/loans.service";
import { InvestmentsService } from "./_services/investments.service";
import { SavingsService } from "./_services/savings.service";
import { ProfileService } from "./_services/profile.service";
import { StorageService } from "./_services/storage.service";
import { LoginComponent } from "./login/login.component";
import { ForgotComponent } from "./forgot/forgot.component";
import { OnboardComponent } from "./onboard/onboard.component";
import { PagenotfoundComponent } from "./pagenotfound/pagenotfound.component";
import { DataService } from "./_services/data.service";
import { FormWizardModule } from "angular-wizard-form";
import { AuthGuard } from "./_guards/auth.guard";
import { LoggedinGuard } from "./_guards/loggedin.guard";
import { MakepaymentComponent } from "./loan/makepayment/makepayment.component";
import { FlutterwaveModule } from "flutterwave-angular-v3";
import { NgxSpinnerModule, NgxSpinnerService } from "ngx-spinner";


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotComponent,
    OnboardComponent,
    PagenotfoundComponent,
    MakepaymentComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule.forRoot(),
    FormWizardModule,
    FlutterwaveModule,
    NgxSpinnerModule,
  ],
  providers: [
    AuthenticationService,
    DashboardService,
    LoansService,
    InvestmentsService,
    SavingsService,
    ProfileService,
    StorageService,
    DataService,
    AuthGuard,
    LoggedinGuard,
    NgxSpinnerService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
