import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-disbursement-list-table',
  templateUrl: './disbursement-list-table.component.html',
  styleUrls: ['./disbursement-list-table.component.css']
})
export class DisbursementListTableComponent implements OnInit {

  constructor() { }

  @Input() disburses = [];
  @Output() sendPayload = new EventEmitter<any>();

  ngOnInit() {
  }

  getParam(id, type) {
    this.sendPayload.emit({ disburseId: id, type });
  }

}
