import { Component, Input, OnInit } from "@angular/core";
import { IRequestOnline } from "src/app/models/request";
import { formatCurrency, formatNumber } from "../../helpers/index";
@Component({
  selector: "app-online-store-detail",
  templateUrl: "./online-store-detail.component.html",
  styleUrls: ["./online-store-detail.component.css"],
})
export class OnlineStoreDetailComponent implements OnInit {
  @Input() request: IRequestOnline | any;
  mobile = window.innerWidth < 768;
  constructor() {}

  ngOnInit() {
    console.log(this.request);
  }

  formatCurrency(currency: any) {
    return formatCurrency(+currency);
  }

  formatNumber(number: any) {
    return formatNumber(+number);
  }
}
