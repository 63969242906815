import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { formatCurrency, getSum } from 'src/app/helpers';

@Component({
  selector: 'app-disburse-approval-item-only',
  templateUrl: './disburse-approval-item-only.component.html',
  styleUrls: ['./disburse-approval-item-only.component.css']
})
export class DisburseApprovalItemOnlyComponent implements OnInit {

  @Input() approved_items = [];
  // @Output() checkedList = new EventEmitter<any>();

  constructor() { }

  ngOnInit() { }

  formatCurrency(currency: string) {
    return formatCurrency(+currency)
  }

  get totalAmount() {
    return getSum(this.approved_items, 'amount')
  }

  // getValidity(validity_status: any) {
  //   if (validity_status) {
  //     return +validity_status > 0 ? 'Yes' : 'No';
  //   }
  //   return 'No';
  // }
  // getStatus(status: any) {
  //   if (status) {
  //     return +status > 0 ? 'Yes' : 'No';
  //   }
  //   return 'No';
  // }



  // checkAll(event) {
  //   this.approval_items.forEach(staff => staff.checked = event.target.checked);
  //   const list = this.checkUsers()
  //   this.checkedList.emit(list);
  // }

  // checkOne(id) {
  //   this.approval_items.map(res => res.id === id ? { ...res, checked: !res.checked } : res);
  //   const list = this.checkUsers()
  //   this.checkedList.emit(list);
  // }

  // checkUsers() {
  //   let result = { length: 0, data: [] };
  //   this.approval_items.forEach(staff => {
  //     if (staff.checked) {
  //       result.length++;
  //       result.data.push(staff);
  //     }
  //   });
  //   return result;
  // }

}
