import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core'; 
import { DataService } from 'src/app/_services/data.service';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.css']
})
export class TopnavComponent implements OnInit {
  @Output() openside = new EventEmitter();
  constructor(private dataService: DataService) { }

  ngOnInit() {
  }
  opensidemenu(){
    this.openside.emit();
  }

}
