import { Component, Input, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { LoansService } from 'src/app/_services/loans.service';
import { StorageService } from 'src/app/_services/storage.service';

@Component({
  selector: 'app-latesttrans',
  templateUrl: './latesttrans.component.html',
  styleUrls: ['./latesttrans.component.css']
})
export class LatesttransComponent implements OnInit {

  @Input() merchant_id :any;
  user:any;
  currentLoan=[];
  data:any;
  @Input() start = '0';
  source = '';
  constructor(private storageService: StorageService, private loansService: LoansService, private authService: AuthenticationService) { 
    this.user = this.storageService.read<any>('user');
  }

  ngOnInit() {
    this.getCurrentLoans(this.merchant_id,this.start,this.source);
  }
  getCurrentLoans(request_id,start,source) {
    this.source = source;
    
    //this.loading = true; 
    this.loansService.latesttrans(request_id,start,source)
      .subscribe(result => {
         this.data = result.data;
        if(this.merchant_id != ''){
          this.start = result.start
        }
      }, err => { 
      });
  }
  ngOnChanges(changes: SimpleChanges) {
    this.start = '0';
    const request_id: SimpleChange = changes.merchant_id.currentValue;
    if (request_id) {
      this.merchant_id = request_id;
      this.getCurrentLoans(request_id,0,'')
    }

  }
}
