import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-merchant-detail-table',
  templateUrl: './merchant-detail-table.component.html',
  styleUrls: ['./merchant-detail-table.component.css']
})
export class MerchantDetailTableComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
