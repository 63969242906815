import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { formatCurrency } from "src/app/helpers";

@Component({
  selector: "app-disburse-approvals-item",
  templateUrl: "./disburse-approvals-item.component.html",
  styleUrls: ["./disburse-approvals-item.component.css"],
})
export class DisburseApprovalsItemComponent implements OnInit {
  constructor() {}

  @Input() pending_approvals: Array<any> = [];
  @Output() sendPayload = new EventEmitter<any>();

  ngOnInit() {
    console.log(this.pending_approvals);
  }

  getId(item, type) {
    this.sendPayload.emit({
      approval_id: item.id,
      disbursement_id: item.id,
      type,
    });
  }

  formatCurrency(currency: any) {
    return formatCurrency(+currency).split(".")[0];
  }
}
