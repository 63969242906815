import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { formatCurrency } from 'src/app/helpers';

@Component({
  selector: 'app-disburse-list-item',
  templateUrl: './disburse-list-item.component.html',
  styleUrls: ['./disburse-list-item.component.css']
})
export class DisburseListItemComponent implements OnInit {
  @Input() disburse_items = [];
  @Output() checkedList = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  getValidity(validity_status: any) {
    if (validity_status) {
      return +validity_status > 0 ? 'Yes' : 'No';
    }
    return 'No';
  }
  getStatus(status: any) {
    if (status) {
      return +status > 0 ? 'Yes' : 'No';
    }
    return 'No';
  }

  formatCurrency(currency: string) {
    return formatCurrency(+currency)
  }

  checkAll(event) {
    this.disburse_items.forEach(staff => staff.checked = event.target.checked);
    const list = this.checkUsers()
    this.checkedList.emit(list);
  }

  checkOne(id) {
    this.disburse_items.map(res => res.id === id ? { ...res, checked: !res.checked } : res);
    const list = this.checkUsers()
    this.checkedList.emit(list);
  }

  checkUsers() {
    let result = { length: 0, data: [] };
    this.disburse_items.forEach(staff => {
      if (staff.checked) {
        result.length++;
        result.data.push(staff);
      }
    });
    return result;
  }

}
