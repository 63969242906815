import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css']
})
export class SelectComponent implements OnInit {

  constructor() { }

  @Output() select = new EventEmitter<any>();
  @Input() options: any[] = [];
  selected_option: any;

  ngOnInit() {
    if (this.options.length) {
      this.selected_option = this.options[0].value;
    }
  }

  sendPayload(page) {
    this.select.emit({ value: this.selected_option, page });
  }

}
