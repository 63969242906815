import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { LoansService } from 'src/app/_services/loans.service';
import { StorageService } from 'src/app/_services/storage.service';

@Component({
  selector: 'app-onboardings',
  templateUrl: './onboardings.component.html',
  styleUrls: ['./onboardings.component.css']
})
export class OnboardingsComponent implements OnInit {

  user:any;
  currentLoan=[];
  constructor(private storageService: StorageService, private loansService: LoansService, private authService: AuthenticationService) { 
    this.user = this.storageService.read<any>('user');
  }

  ngOnInit() {
    this.getCurrentLoans();
  }
  getCurrentLoans() {

    //this.loading = true; 
    this.loansService.getOnboardings()
      .subscribe(result => {
         this.currentLoan = result.data.currentLoan;
      }, err => { 
      });
  }
}
