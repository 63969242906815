import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { InvestmentsService } from 'src/app/_services/investments.service';
import { LoansService } from 'src/app/_services/loans.service';
import { ProfileService } from 'src/app/_services/profile.service';
import { StorageService } from 'src/app/_services/storage.service';
@Component({
  selector: 'app-investmentsportfolioitems',
  templateUrl: './investmentsportfolioitems.component.html',
  styleUrls: ['./investmentsportfolioitems.component.css']
})
export class InvestmentsportfolioitemsComponent implements OnInit {

  
  @Input('mini') mini = false;
  user:any;
  data:any;
  constructor(private storageService: StorageService, private invService: InvestmentsService, private authService: AuthenticationService) { 
    this.user = this.storageService.read<any>('user');
  }

  ngOnInit() {
    this.getUserBanks();
  }
  getUserBanks() {

    //this.loading = true; 
    this.invService.geInvestmentPortfolio({token: this.user.token,'mini': this.mini})
      .subscribe(result => {
         this.data = result.loans;
      }, err => { 
      });
  }
}