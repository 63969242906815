import { DialogComponent } from "./dialog/dialog.component";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { SidemenuComponent } from "./sidemenu/sidemenu.component";
import { MenuComponent } from "./menu/menu.component";
import { TopnavComponent } from "./topnav/topnav.component";
import { BankaccountsitemsComponent } from "./bankaccountsitems/bankaccountsitems.component";
import { BankcardsitemsComponent } from "./bankcardsitems/bankcardsitems.component";
import { LoanportfolioitemsComponent } from "./loanportfolioitems/loanportfolioitems.component";
import { SavingsportfolioitemsComponent } from "./savingsportfolioitems/savingsportfolioitems.component";
import { InvestmentsportfolioitemsComponent } from "./investmentsportfolioitems/investmentsportfolioitems.component";
import { RecenttransactionsComponent } from "./recenttransactions/recenttransactions.component";
import { FlutterwaveModule } from "flutterwave-angular-v3";
import { OnboardingsComponent } from "./onboardings/onboardings.component";
import { AccountsopenedComponent } from "./accountsopened/accountsopened.component";
import { InflowsComponent } from "./inflows/inflows.component";
import { OutflowsComponent } from "./outflows/outflows.component";
import { RevenuesComponent } from "./revenues/revenues.component";
import { LatestmerchantsComponent } from "./latestmerchants/latestmerchants.component";
import { LatesttransComponent } from "./latesttrans/latesttrans.component";
import { LatestwithComponent } from "./latestwith/latestwith.component";
import { SourcetransactionsComponent } from "./sourcetransactions/sourcetransactions.component";
import { AccountnumbersComponent } from "./accountnumbers/accountnumbers.component";
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TransactionlogsComponent } from "./transactionlogs/transactionlogs.component";
import { GlobalReportComponent } from "./global-report/global-report.component";
import { StaffTrackingComponent } from "./staff-tracking/staff-tracking.component";
import { InflowSummaryComponent } from "./inflow-summary/inflow-summary.component";
import { OutflowSummaryComponent } from "./outflow-summary/outflow-summary.component";
import { MerchantReportComponent } from "./merchant-report/merchant-report.component";
import { StartEndDateComponent } from "./global/start-end-date/start-end-date.component";
import { SelectComponent } from "./global/select/select.component";
import { LoaderComponent } from "./global/loader/loader.component";
import { MerchantListTableComponent } from "./merchant-list-table/merchant-list-table.component";
import { MerchantCardComponent } from "./merchant-card/merchant-card.component";
import { CommissionComponent } from "./commission/commission.component";
import { CommissionReportTableComponent } from "./commission-report-table/commission-report-table.component";
import { CommissionRunTableComponent } from "./commission-run-table/commission-run-table.component";
import { InactiveMerchantComponent } from "./inactive-merchant/inactive-merchant.component";
import { StaffTrackingTableComponent } from "./staff-tracking-table/staff-tracking-table.component";
import { MerchantListDetailTableComponent } from "./merchant-list-detail-table/merchant-list-detail-table.component";
import { StaffReportComponent } from './staff-report/staff-report.component';
import { StaffReportTableComponent } from './staff-report-table/staff-report-table.component';
import { RequestListComponent } from './request-list/request-list.component';
import { RequestListCardComponent } from './request-list-card/request-list-card.component';
import { WalletComponent } from './wallet/wallet.component';
import { WalletListTableComponent } from './wallet-list-table/wallet-list-table.component';
import { NoResultComponent } from './global/no-result/no-result.component';
import { DisbursementComponent } from './disbursement/disbursement.component';
import { DisbursementListTableComponent } from './disbursement-list-table/disbursement-list-table.component';
import { DisburseListItemComponent } from './disburse-list-item/disburse-list-item.component';
import { PendingApprovalItemComponent } from './pending-approval-item/pending-approval-item.component';
import { PendingApprovalsComponent } from './pending-approvals/pending-approvals.component';
import { WalletOperationComponent } from './wallet-operation/wallet-operation.component';
import { CommissionTableComponent } from './commission-table/commission-table.component';
import { CommissionDetailTableComponent } from './commission-detail-table/commission-detail-table.component';
import { ProspectListComponent } from './prospect-list/prospect-list.component';
import { PendingApprovalDetailComponent } from './pending-approval-detail/pending-approval-detail.component';
import { PendingApprovalDetailTableComponent } from './pending-approval-detail-table/pending-approval-detail-table.component';
import { DisburseApprovalsComponent } from './disburse-approvals/disburse-approvals.component';
import { DisburseApprovalsItemComponent } from './disburse-approvals-item/disburse-approvals-item.component';
import { DisburseApprovalsDetailComponent } from './disburse-approvals-detail/disburse-approvals-detail.component';
import { MerchantDetailTableComponent } from './merchant-detail-table/merchant-detail-table.component';
import { AppTrackingComponent } from './app-tracking/app-tracking.component';
import { AppTrackingTableComponent } from './app-tracking-table/app-tracking-table.component';
import { TransactionTrackingComponent } from './transaction-tracking/transaction-tracking.component';
import { TransactionTrackingTableComponent } from "./transaction-tracking-table/transaction-tracking-table.component";
import { WalletBalanceComponent } from './wallet-balance/wallet-balance.component';
import { OrdersComponent } from './orders/orders.component';
import { OnlineOrdersComponent } from './online-orders/online-orders.component';
import { StoreOrdersComponent } from './store-orders/store-orders.component';
import { SquareBoxComponent } from './square-box/square-box.component';
import { StoreOrderDetailsComponent } from './store-order-details/store-order-details.component';
import { DisburseApprovalItemOnlyComponent } from './disburse-approval-item-only/disburse-approval-item-only.component';
import { RepaymentThroughWalletComponent } from './repayment-through-wallet/repayment-through-wallet.component';
import { RepaymentThroughWalletItemComponent } from './repayment-through-wallet-item/repayment-through-wallet-item.component';
import { ReverserThroughWalletComponent } from './reverser-through-wallet/reverser-through-wallet.component';
import { ReverserThroughWalletItemComponent } from './reverser-through-wallet-item/reverser-through-wallet-item.component';
import { BankSummaryTableComponent } from './bank-summary-table/bank-summary-table.component';
import { OnlineStoreDetailComponent } from './online-store-detail/online-store-detail.component';
import { RepaymentRateComponent } from './repayment-rate/repayment-rate.component';
import { ManageReferralComponent } from './manage-referral/manage-referral.component';
import { ManageReferralTableComponent } from './manage-referral-table/manage-referral-table.component';
import { ManageReferralDetailTableComponent } from './manage-referral-detail-table/manage-referral-detail-table.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule
  ],
  entryComponents: [],
  declarations: [
    SidemenuComponent,
    MenuComponent,
    TopnavComponent,
    BankaccountsitemsComponent,
    BankcardsitemsComponent,
    LoanportfolioitemsComponent,
    SavingsportfolioitemsComponent,
    InvestmentsportfolioitemsComponent,
    RecenttransactionsComponent,
    OnboardingsComponent,
    AccountsopenedComponent,
    InflowsComponent,
    OutflowsComponent,
    RevenuesComponent,
    LatestmerchantsComponent,
    LatesttransComponent,
    LatestwithComponent,
    SourcetransactionsComponent,
    TransactionlogsComponent,
    DialogComponent,
    AccountnumbersComponent,
    GlobalReportComponent,
    StaffTrackingComponent,
    InflowSummaryComponent,
    OutflowSummaryComponent,
    MerchantReportComponent,
    StartEndDateComponent,
    SelectComponent,
    LoaderComponent,
    StaffTrackingTableComponent,
    MerchantListTableComponent,
    MerchantListDetailTableComponent,
    MerchantCardComponent,
    CommissionComponent,
    CommissionReportTableComponent,
    CommissionRunTableComponent,
    InactiveMerchantComponent,
    StaffReportComponent,
    StaffReportTableComponent,
    RequestListComponent,
    RequestListCardComponent,
    WalletComponent,
    WalletListTableComponent,
    NoResultComponent,
    DisbursementComponent,
    DisbursementListTableComponent,
    DisburseListItemComponent,
    PendingApprovalItemComponent,
    PendingApprovalsComponent,
    WalletOperationComponent,
    CommissionTableComponent,
    CommissionDetailTableComponent,
    ProspectListComponent,
    PendingApprovalDetailComponent,
    PendingApprovalDetailTableComponent,
    DisburseApprovalsComponent,
    DisburseApprovalsItemComponent,
    DisburseApprovalsDetailComponent,
    MerchantDetailTableComponent,
    AppTrackingComponent,
    TransactionTrackingComponent,
    TransactionTrackingTableComponent,
    AppTrackingTableComponent,
    WalletBalanceComponent,
    OrdersComponent,
    OnlineOrdersComponent,
    StoreOrdersComponent,
    SquareBoxComponent,
    StoreOrderDetailsComponent,
    DisburseApprovalItemOnlyComponent,
    RepaymentThroughWalletComponent,
    RepaymentThroughWalletItemComponent,
    ReverserThroughWalletComponent,
    ReverserThroughWalletItemComponent,
    BankSummaryTableComponent,
    OnlineStoreDetailComponent,
    RepaymentRateComponent,
    ManageReferralComponent,
    ManageReferralTableComponent,
    ManageReferralDetailTableComponent
  ],
  exports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SidemenuComponent,
    MenuComponent,
    TopnavComponent,
    BankaccountsitemsComponent,
    BankcardsitemsComponent,
    LoanportfolioitemsComponent,
    SavingsportfolioitemsComponent,
    InvestmentsportfolioitemsComponent,
    RecenttransactionsComponent,
    FlutterwaveModule,
    OnboardingsComponent,
    AccountsopenedComponent,
    InflowsComponent,
    OutflowsComponent,
    RevenuesComponent,
    LatestmerchantsComponent,
    LatesttransComponent,
    LatestwithComponent,
    SourcetransactionsComponent,
    TransactionlogsComponent,
    DialogComponent,
    AccountnumbersComponent,
    MatTabsModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    StaffTrackingComponent,
    InflowSummaryComponent,
    OutflowSummaryComponent,
    MerchantReportComponent,
    StartEndDateComponent,
    SelectComponent,
    StaffTrackingTableComponent,
    MerchantListTableComponent,
    MerchantListDetailTableComponent,
    MerchantCardComponent,
    CommissionComponent,
    CommissionReportTableComponent,
    CommissionRunTableComponent,
    InactiveMerchantComponent,
    LoaderComponent,

  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
