import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-staff-tracking-table',
  templateUrl: './staff-tracking-table.component.html',
  styleUrls: ['./staff-tracking-table.component.css']
})
export class StaffTrackingTableComponent implements OnInit {

  constructor() { }
  @Input() staff_reports = [];
  @Input() isDeactivating: boolean;
  @Input() active_item: boolean;
  @Output() item = new EventEmitter<any>();
  @Output() checkedStaffList = new EventEmitter<any>();
  @Output() sortStaff = new EventEmitter<any>();
  ngOnInit() { }


  sendItem(item, type) {
    this.item.emit({ item, type });
  }

  checkAll(event) {
    this.staff_reports.forEach(staff => staff.checked = event.target.checked);
    const list = this.checkUsers()
    this.checkedStaffList.emit(list);
  }

  checkOne(mobile) {
    this.staff_reports.map(staff => staff.mobile === mobile ? { ...staff, checked: !staff.checked } : staff);
    const list = this.checkUsers()
    this.checkedStaffList.emit(list);
  }

  checkUsers() {
    let result = { length: 0, data: [] };
    this.staff_reports.forEach(staff => {
      if (staff.checked) {
        result.length++;
        result.data.push(staff);
      }
    });
    return result;
  }

  getRegion(region) {
    return `Region ${region}`
  }

  sortList() {
    this.sortStaff.emit()
  }

}

