import { Injectable } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js';
@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor() { }

  notify({ type, message, icon }) {
    Swal.fire({
      title: type,
      text: message,
      icon: icon,
    });
  }

  removeUser(key): void {
    localStorage.removeItem(key);
  }


}
