import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { ApiService } from 'src/app/_services/api.service';
import { checkAccountAvailability } from 'src/app/helpers';

@Component({
  selector: 'app-merchant-card',
  templateUrl: './merchant-card.component.html',
  styleUrls: ['./merchant-card.component.css']
})
export class MerchantCardComponent implements OnInit, AfterViewInit {

  @ViewChild('root', { static: false }) root: ElementRef<HTMLDivElement>


  constructor(private _api: ApiService) { }

  @Input() merchant_detail: any = {};
  @Output() send_count = new EventEmitter<any>();
  loading: boolean = false;
  isLoadingBVN: boolean = false;
  other_detail: any = null;
  bvn: string = '';
  debounceTimer: ReturnType<typeof setTimeout>;

  ngOnInit() {
    console.log(this.merchant_detail)
  }

  ngAfterViewInit() {
    let options = { root: document.querySelector('app-dialog .body.with-header') };
    let observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (!this.other_detail && !this.loading) {
            this.debounce(this.getMerchantDetail, 1000);
          }
          if (!this.bvn && !this.isLoadingBVN) {
            this.debounce(this.getBVNDetail, 1000);
          }
        } else {
          clearTimeout(this.debounceTimer)
        }
      })
    }, options);
    observer.observe(this.root.nativeElement);
  }

  debounce(func: Function, delay: number) {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      func.call(this);
    }, delay);
  }

  async getMerchantDetail() {
    try {
      this.loading = true;
      const { data: detail } = await this._api.getMerchantListDetail({ merchant_id: [this.merchant_detail.id], period: 2 })
      this.other_detail = detail;
      const inflow = detail.inflows ? { inflow: detail.inflows } : {}
      const outflow = detail.outflows ? { outflow: detail.outflows } : {}
      this.send_count.emit({ ...inflow, ...outflow })
      this.loading = false;
    } catch (error) {
      console.log(error)
      this.loading = false;
    }
  }

  checkAccounts = (data: any) => {
    return checkAccountAvailability(data);
  };

  async getBVNDetail() {
    try {
      this.isLoadingBVN = true;
      const { data } = await this._api.getBVNDetail(this.merchant_detail.phone);
      this.bvn = data;
      this.isLoadingBVN = false;
    } catch (error) {
      this.isLoadingBVN = false;
    }
  }

}
