import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-merchant-list-table',
  templateUrl: './merchant-list-table.component.html',
  styleUrls: ['./merchant-list-table.component.css']
})
export class MerchantListTableComponent implements OnInit {

  @Input() merchant_list: any = [];
  @Input() staff: any = {};
  @Output() viewMerchantListDetail = new EventEmitter<any>();
  transactions: any = {};
  total_inflows: number = 0;
  total_outflows: number = 0;
  ngOnInit() { }

  sendPayload(payload) {
    this.viewMerchantListDetail.emit(payload);
  }

  getTransactionCount({ inflow, outflow }) {
    this.total_inflows = this.total_inflows + (+inflow)
    this.total_outflows = this.total_outflows + (+outflow)
  }

}
