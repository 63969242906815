import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-start-end-date',
  templateUrl: './start-end-date.component.html',
  styleUrls: ['./start-end-date.component.css']
})
export class StartEndDateComponent implements OnInit {
  constructor() { }
  @Output() filterByDate = new EventEmitter<any>();
  startDateRaw: string = '';
  endDateRaw: string = '';
  startDate: any = '';
  endDate: any = '';
  ngOnInit() { }

  sendDatePayload(page: number) {
    const payload = { start_date: this.startDate, end_date: this.endDate, page };
    this.filterByDate.emit(payload);
  }
}
