import { Component, Input, OnInit } from '@angular/core';
import { formatCurrency } from 'src/app/helpers';

@Component({
  selector: 'app-prospect-list',
  templateUrl: './prospect-list.component.html',
  styleUrls: ['./prospect-list.component.css']
})
export class ProspectListComponent implements OnInit {

  constructor() { }

  @Input() prospect_list: any = [];
  @Input() staff: any = {};

  ngOnInit() {
  }

  formatCurrency(value) {
    return formatCurrency(+value);
  }

}
