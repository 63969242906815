import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/_services/api.service';
import { GeneralService } from 'src/app/_services/general.service';
import { currentDate, formatCurrency, getToday, formatStaffAlphabetically } from 'src/app/helpers';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { getRoutes } from '../../helpers/util';

@Component({
  selector: 'app-staff-report',
  templateUrl: './staff-report.component.html',
  styleUrls: ['./staff-report.component.css']
})
export class StaffReportComponent implements OnInit {

  constructor(
    private _api: ApiService,
    private _general: GeneralService,
  ) { }
  isLoading: boolean = false;
  isFetching: boolean = false;
  isLoadingProspect: boolean = false;
  isFetchingProspect: boolean = false;
  searched: boolean = false;
  showCloseIcon: boolean = false;
  is_searching: boolean = false;
  filtered: boolean = false;
  isToday: boolean = false;
  staff_reports: Array<any> = [];
  startDate = '';
  endDate = '';
  initialDate = currentDate();
  today = getToday();
  isLoadingDetail: boolean = false;
  merchant_ids: Array<string> = [];
  current_staff = {};
  current_merchant = {};
  phones: Array<any> = [];
  request_list: Array<any> = [];
  prospect_list: Array<any> = [];
  durations: Array<object> = [
    { title: 'Duration', value: 'duration' },
    { title: 'Today', value: 'today' },
    { title: 'This Month', value: 'this month' }
  ];
  modals = {
    request_list: '',
    prospect_view: '',
  };
  pagination = {
    cursor: 0,
    end: false,
    limit: 50,
    page: 1,
  };
  detail_date = currentDate();

  sort_order: string = 'default';
  searchedStaffs: any[] = [];
  searchText: string;
  user = JSON.parse(localStorage.getItem('user')) || {};

  public get staffs(): any[] {
    if (this.searchText) return this.searchedStaffs;
    else return this.staff_reports;
  }

  ngOnInit() {
    this.getStaffReport(this.initialDate, 1)
  }

  async getStaffReport(payload, page) {
    try {
      if (page === 1) this.isLoading = true;
      else this.isFetching = true;
      const { data } = await this._api.getStaffReportByLoaBooked({ ...payload, merchant_loan_summary: true });
      this.staff_reports = data.sort((a, b) => b.booked_loans_count - a.booked_loans_count)
      this.isLoading = false;
      this.isFetching = false;
    } catch (error) {
      this.isLoading = false;
      this.isFetching = false;
      console.log(error)
    }
  }

  filterStaff({ start_date, end_date, page }) {
    this.filtered = true;
    this.startDate = start_date;
    this.endDate = end_date;
    this.detail_date = { start_date, end_date }
    this.getStaffReport({ start_date, end_date }, page)
  }

  filterByDuration({ value, page }) {
    if (value === 'duration') return;
    this.filtered = true;
    const date = value === 'today' ? this.today : this.initialDate
    this.detail_date = date;
    this.isToday = value === 'today' ? true : false;
    this.getStaffReport(date, page)
  }

  searchStaff() {
    this.searched = true;
    this.searchedStaffs = this.staff_reports.filter((data) => {
      return data.full_name.toLowerCase().includes(this.searchText.toLowerCase()) || data.mobile.includes(this.searchText);
    });
  }

  sortStaff() {
    if (this.sort_order === 'default') {
      this.staff_reports = this.staff_reports.sort((a, b) => a.full_name.localeCompare(b.full_name))
      this.sort_order = 'full_name';
    } else {
      this.staff_reports = this.staff_reports.sort((a, b) => b.booked_loans_count - a.booked_loans_count);
      this.sort_order = 'default'
    }
  }

  getItem({ item, type }) {
    this.current_staff = item;
    if (type === 'prospect') {
      this.modals.prospect_view = 'open';
      this.getAgentProspect({ item, page: this.pagination.page, next_cursor: this.pagination.cursor });
    } else {
      this.modals.request_list = 'open';
      this.getRequests(item, type, this.detail_date);
    }
  }

  // async getMerchantDetail(merchants) {
  //   if (merchants.length) {
  //     const promises = merchants.reduce((acc, merchant) => {
  //       acc.push(
  //         new Promise((resolve, reject) => {
  //           this._api.getMerchantListDetail({ merchant_id: [merchant.id], period: 2 })
  //             .then(({ data }) => resolve({ ...merchant, ...data }))
  //             .catch(err => reject(err))
  //         })
  //       )
  //       return acc;
  //     }, [])
  //     return await Promise.all(promises);
  //   } else {
  //     return []
  //   }
  // }

  async getRequests(item, type, { start_date, end_date }) {
    try {
      this.isLoadingDetail = true;
      const { data, status } = await this._api.getRequests({ agent_id: item.id, status: type, start_date, end_date })
      if (status) {
        this.current_staff = item;
        this.request_list = data;
        this.isLoadingDetail = false;
      } else {
        this.request_list = [];
        this.current_staff = {};
        this.isLoadingDetail = false;
      }
    } catch (error) {
      this.current_staff = {};
      this.request_list = [];
      this.isLoadingDetail = false;
    }
  }
  async getAgentProspect({ item, page, next_cursor }) {
    try {
      if (page === 1) this.isLoadingProspect = true;
      else this.isFetchingProspect = true;
      const { data, status } = await this._api.getAgentProspects({ page, next_cursor, agent_id: item.id, limit: this.pagination.limit })
      if (status) {
        this.current_staff = item;
        this.prospect_list = page === 1 ? data.data : [...this.prospect_list, ...data.data];
        this.pagination.cursor = data.cursor;
        this.pagination.end = data.end;
        this.isLoadingProspect = false;
        this.isFetchingProspect = false;
      } else {
        this.prospect_list = [];
        this.current_staff = {};
        this.isLoadingProspect = false;
        this.isFetchingProspect = false;
      }
    } catch (error) {
      this.current_staff = {};
      this.prospect_list = [];
      this.isLoadingProspect = false;
      this.isFetchingProspect = false;
    }
  }
  loadMore() {
    this.getAgentProspect({ item: this.current_staff, page: ++this.pagination.page, next_cursor: this.pagination.cursor })
  }
}
