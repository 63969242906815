import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-commission-report-table',
  templateUrl: './commission-report-table.component.html',
  styleUrls: ['./commission-report-table.component.css']
})
export class CommissionReportTableComponent implements OnInit {

  constructor() { }

  @Input() commission_reports = [];

  ngOnInit() {
  }

  formatRegion(region) {
    return `Region ${region}`;
  }

}
