import { Component, OnInit, Input } from '@angular/core';
import { formatCurrency } from 'src/app/helpers';

@Component({
  selector: 'app-repayment-through-wallet-item',
  templateUrl: './repayment-through-wallet-item.component.html',
  styleUrls: ['./repayment-through-wallet-item.component.css']
})
export class RepaymentThroughWalletItemComponent implements OnInit {

  @Input() items = [];

  constructor() { }

  ngOnInit() {
  }

  formatCurrency(currency: any) {
    return formatCurrency(currency);
  }

}
