import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "x-api-key":
      "OBAUS1aYGx4YQFhWymCXgLK7rXpmoLv3gtj9ufi8N8wcVxCMvP5RVb9Wp5Bzcn0d",
  }),
};

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  constructor(private http: HttpClient) {}

  initializeApplication(): Observable<any> {
    return this.http.post(
      "https://mobile.creditclan.com/webapi/v1/apikey",
      JSON.stringify({
        apikey:
          "OBAUS1aYGx4YQFhWymCXgLK7rXpmoLv3gtj9ufi8N8wcVxCMvP5RVb9Wp5Bzcn0d",
      }),
      httpOptions
    );
  }
  initializeSettings(): Observable<any> {
    return this.http.get(
      "https://mobile.creditclan.com/webapi/v1/loan/settings",
      httpOptions
    );
  }
  login(data: object): Observable<any> {
    return this.http.post(
      "https://creditclanapis.creditclan.com/api/v2/loan/authenticate_omni",
      data
    );
  }
  register(data: object): Observable<any> {
    return this.http.post(
      "https://mobile.creditclan.com/api/v3/customer/register/user",
      data,
      httpOptions
    );
  }
  forgot(data: object): Observable<any> {
    return this.http.post(
      "https://mobile.creditclan.com/api/v3/customer/reset/password",
      data,
      httpOptions
    );
  }
  logout(): void {
    localStorage.removeItem("user");
  }
}
