import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { formatCurrency } from "src/app/helpers";

@Component({
  selector: "app-pending-approval-item",
  templateUrl: "./pending-approval-item.component.html",
  styleUrls: ["./pending-approval-item.component.css"],
})
export class PendingApprovalItemComponent implements OnInit {
  constructor() {}

  @Input() pending_approvals: Array<any> = [];
  @Output() setItemId = new EventEmitter<any>();

  ngOnInit() {
    // console.log(this.pending_approvals);
  }

  getId(item) {
    this.setItemId.emit({
      approval_id: item.id,
      disbursement_id: item.wallet_disbursement_id,
    });
  }

  formatCurrency(currency: any) {
    return formatCurrency(+currency);
  }
}
