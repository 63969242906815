import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { formatNumber } from '../../helpers/index';

@Component({
  selector: 'app-staff-report-table',
  templateUrl: './staff-report-table.component.html',
  styleUrls: ['./staff-report-table.component.css']
})
export class StaffReportTableComponent implements OnInit {

  @Input() staff_reports = [];
  @Output() currentItem = new EventEmitter<any>();
  @Output() sortStaff = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  sortList() {
    this.sortStaff.emit()
  }

  sendItem(item, type) {
    this.currentItem.emit({ item, type });
  }

  formatNumber(value) {
    return formatNumber(+value);
  }
}
