import { Component, Input, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { LoansService } from 'src/app/_services/loans.service';
import { StorageService } from 'src/app/_services/storage.service';

@Component({
  selector: 'app-latestwith',
  templateUrl: './latestwith.component.html',
  styleUrls: ['./latestwith.component.css']
})
export class LatestwithComponent implements OnInit {

  @Input() merchant_id = '';
  user: any;
  currentLoan = [];
  data: any;
  @Input() start = '0';
  constructor(private storageService: StorageService, private loansService: LoansService, private authService: AuthenticationService) {
    this.user = this.storageService.read<any>('user');
  }

  ngOnInit() {
    this.getCurrentLoans(this.merchant_id);
  }
  getCurrentLoans(request_id) {
    console.log(this.start, 'Latest Trans')
    //this.loading = true;
    this.loansService.latestwith(request_id, this.start)
      .subscribe(result => {
        console.log(result.data);
        this.data = result.data;
        if (this.merchant_id != '') {
          this.start = result.start
        }
      }, err => {
      });
  }
  ngOnChanges(changes: SimpleChanges) {
    this.start = '0';
    const request_id: SimpleChange = changes.merchant_id.currentValue;
    if (request_id) {
      this.getCurrentLoans(request_id)
    }

  }
}
