import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { DataService } from 'src/app/_services/data.service';
import { InvestmentsService } from 'src/app/_services/investments.service';
import { LoansService } from 'src/app/_services/loans.service';
import { ProfileService } from 'src/app/_services/profile.service';
import { StorageService } from 'src/app/_services/storage.service';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.css']
})
export class SidemenuComponent implements OnInit {

  
  @Input('mini') mini = false;
  user:any;
  data:any;
  constructor(private dataService: DataService,private storageService: StorageService, private invService: ProfileService, private authService: AuthenticationService) { 
    this.user = this.storageService.read<any>('user');
     
  }

  ngOnInit() { 

  } 

}
