import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-merchant-list-detail-table',
  templateUrl: './merchant-list-detail-table.component.html',
  styleUrls: ['./merchant-list-detail-table.component.css']
})
export class MerchantListDetailTableComponent implements OnInit {

  constructor() { }

  @Input() merchant_list_detail: any = {};
  @Input() merchant = {};

  get TotalCount() {
    return (+this.merchant_list_detail.inflows) + (+this.merchant_list_detail.outflows);
  }

  ngOnInit() {
    console.log(this.merchant_list_detail)
    console.log(this.merchant)
  }

}
