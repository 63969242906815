import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators, } from '@angular/forms';
import { ApiService } from 'src/app/_services/api.service';
import { StoreService } from 'src/app/_services/store.service';
import { numberValidator } from 'src/app/customvalidators/number.validator';


@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.css']
})
export class WalletComponent implements OnInit {

  new_wallet_form: FormGroup;
  is_loading: boolean = false;
  is_fetching: boolean = false;
  is_creating: boolean = false;
  response_message: string = '';
  wallets: Array<any> = [];
  pagination = {
    page: 1
  };
  modals = {
    new_wallet_form: ''
  }



  constructor(
    private _api: ApiService,
    private fb: FormBuilder,
    private store: StoreService
  ) {
    this.new_wallet_form = this.fb.group({
      'title': new FormControl('', Validators.compose([
        Validators.required,
      ])),
      'purpose': new FormControl('', Validators.compose([
        Validators.required,
      ])),
      'max_daily_withdrawal': new FormControl('', Validators.compose([
        Validators.required,
        numberValidator()
      ])),
      'max_single_withdrawal': new FormControl('', Validators.compose([
        Validators.required,
        numberValidator()
      ])),
      'first_approval_email': new FormControl('', Validators.compose([
        Validators.required,
        Validators.email
      ])),
      'second_approval_email': new FormControl('', Validators.compose([
        Validators.required,
        Validators.email
      ])),
      'description': new FormControl('', Validators.compose([
        Validators.required,
      ])),
    });

  }

  ngOnInit() {
    this.getWallets(this.pagination.page)
  }

  async getWallets(page) {
    try {
      if (page === 1) this.is_loading = true;
      else this.is_fetching = true;
      const { data } = await this._api.getWallets();
      this.wallets = data
      this.is_loading = false;
      this.is_fetching = false;
    } catch (error) {
      this.is_loading = false;
      this.is_fetching = false;
      console.log(error)
    }
  }

  async submitForm() {
    this.is_creating = true;
    const form_value = this.new_wallet_form.value
    const user = this.store.loggedInUser()
    const userId = user ? user.account_id : '';
    form_value['added_by'] = userId;
    try {
      const { status } = await this._api.createWallet(form_value);
      if (status) {
        this.response_message = 'success';
        this.is_creating = false;
        this.new_wallet_form.reset()
        setTimeout(() => {
          this.response_message = '';
        }, 2000);
      } else {
        this.is_creating = false;
        this.response_message = 'error';
      }
    } catch (error) {
      this.is_creating = false;
      this.response_message = 'error';
    }

  }

}
