import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { formatNumber } from 'src/app/helpers';
// import { formatNumber } from 'src/helpers';
@Component({
  selector: 'app-merchant-report',
  templateUrl: './merchant-report.component.html',
  styleUrls: ['./merchant-report.component.css']
})
export class MerchantReportComponent implements OnInit {

  constructor() { }
  @Input() merchant_reports = [];
  // @Output() sendMerchant = new EventEmitter<any>();
  @Output() sendMerchant = new EventEmitter<any>();

  ngOnInit() { }

  formatNumber(value) {
    return formatNumber(+value)
  }
  sendItem(merchant) {
    this.sendMerchant.emit(merchant);
  }
}
