import { keysToCheck } from "./util";

export const currentDate = () => {
  let today1 = new Date();
  let year1 = today1.getFullYear();
  let month1 = today1.getMonth() + 1;
  let firstDay1 = new Date(year1, month1 - 1, 1);
  let dateObj1 = new Date(firstDay1);
  let timezoneOffset1 = dateObj1.getTimezoneOffset() * 60000;
  let formattedDate1 = new Date(dateObj1.getTime() - timezoneOffset1).toISOString().slice(0, 10);

  let today2 = new Date();
  let year2 = today2.getFullYear();
  let month2 = today2.getMonth() + 1;
  let lastDay2 = new Date(year2, month2, 0);
  let timezoneOffset2 = lastDay2.getTimezoneOffset() * 60000;
  let formattedDate2 = new Date(lastDay2.getTime() - timezoneOffset2).toISOString().slice(0, 10);

  return { start_date: formattedDate1, end_date: formattedDate2 }
}

export const previousMonth = () => {
  let today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth();

  // Calculate the first day of the previous month
  let firstDay = new Date(year, month - 1, 1);
  let dateObj1 = new Date(firstDay);
  let timezoneOffset1 = dateObj1.getTimezoneOffset() * 60000;
  let formattedDate1 = new Date(dateObj1.getTime() - timezoneOffset1).toISOString().slice(0, 10);

  // Calculate the last day of the previous month
  let lastDay = new Date(year, month, 0);
  let timezoneOffset2 = lastDay.getTimezoneOffset() * 60000;
  let formattedDate2 = new Date(lastDay.getTime() - timezoneOffset2).toISOString().slice(0, 10);

  return { start_date: formattedDate1, end_date: formattedDate2 };
}

export const getToday = () => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;

  return { start_date: formattedDate, end_date: formattedDate }
}

export const getYesterday = () => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 1);

  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;

  return { start_date: formattedDate, end_date: formattedDate };
};

export const formatCurrency = (value) => {
  return new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
  }).format(+value);
}

export const formatNumber = (value: number) => {
  return value.toLocaleString(undefined, { style: 'decimal' });
}

export const formatStaffAlphabetically = (lists: Array<any>) => {
  return lists.sort(function (a, b) {
    let nameA = a.full_name.split(" ")[0].toLowerCase();
    let nameB = b.full_name.split(" ")[0].toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
}

export const IsValidJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const formatTime = (date) => {
  let hours = new Date(date).getHours();
  let minutes: any = new Date(date).getMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

export const getSum = (data, key) => {
  let sum = 0;
  for (const item of data) {
    if (item[key] !== null) {
      sum += parseInt(item[key], 10);
    }
  }
  return formatCurrency(+sum);
}

const isValid = (value: string) => {
  return value !== "XXXXXXXXXX" && /^\d+$/.test(value);
};

export const checkAccountAvailability = (data: any) => {
  for (const key of keysToCheck) {
    if (isValid(data[key])) {
      return true;
    }
  }
  return false;
};
