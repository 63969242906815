import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { formatCurrency } from 'src/app/helpers';
// import { formatCurrency } from '../../../helpers/index';

@Component({
  selector: 'app-commission-run-table',
  templateUrl: './commission-run-table.component.html',
  styleUrls: ['./commission-run-table.component.css']
})
export class CommissionRunTableComponent implements OnInit {

  constructor() { }

  @Input() run_commission = [];
  // @Output() actionPayload = new EventEmitter<any>();

  ngOnInit() { }

  // sendPayload(item: object, identifier: string) {
  //   this.actionPayload.emit({ item, identifier });
  // }

  formatCurrency(value) {
    return formatCurrency(value)
  }

}
