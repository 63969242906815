import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthenticationService } from './_services/authentication.service';
import { DataService } from './_services/data.service';
import { StorageService } from './_services/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(private spinner: NgxSpinnerService,private storageService: StorageService, private authService: AuthenticationService, private dataService: DataService) {

    this.authService.initializeApplication()
      .subscribe(result => {
        if (result.status == true) {
     //     this.initializeSettings();
        }

      }, err => { });

      this.dataService.showSpinner.subscribe(res=>{
        this.spinner.show();
      });
      this.dataService.hideSpinner.subscribe(res=>{ 
        this.spinner.hide();
      });

  }

  ngOnInit() {
    
  }
  initializeSettings() {
    this.dataService.showSpinner.emit();
    this.authService.initializeSettings()
      .subscribe(result => {
        this.dataService.hideSpinner.emit();
        if (result.status == true) {
          console.log(result)
          localStorage.setItem("loan_settings", JSON.stringify(result.data));
          
        }

      }, err => { });

  }
  
  
}