import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { LoansService } from 'src/app/_services/loans.service';
import { StorageService } from 'src/app/_services/storage.service';
import { ApiService } from 'src/app/_services/api.service';
import { currentDate, getToday, formatNumber } from 'src/app/helpers';


@Component({
  selector: 'app-transactionlogs',
  templateUrl: './transactionlogs.component.html',
  styleUrls: ['./transactionlogs.component.css']
})
export class TransactionlogsComponent implements OnInit {
  @Input() merchant_id = '';
  user: any;
  currentLoan = [];
  data: any;
  @Input() start = '0';
  bank_id = '';
  account_number = '';
  merchant_reports: Array<any> = [];
  inflow_reports: Array<any> = [];
  originalRecords: Array<any> = [];
  searchedRecords: Array<any> = [];
  isToday: boolean = false;
  merchants: any;
  merchant_detail: any = null;
  searchText: string;
  startDate = '';
  endDate = '';
  initialDate = currentDate();
  today = getToday();
  detail_date = currentDate();
  reports: string[] = [];
  searched: boolean = false;
  isLoading: boolean = false;
  isFetching: boolean = false;
  filtered: boolean = false;
  isLoadingDetail: boolean = false;
  durations: Array<object> = [
    { title: 'Duration', value: 'duration' },
    { title: 'Today', value: 'today' },
    { title: 'This Month', value: 'this month' }
  ];

  pagination = {
    cursor: 0,
    end: false,
    limit: 50,
    page: 1,
  };

  modals = {
    view: '',
  }

  constructor(
    private storageService: StorageService,
    private loansService: LoansService,
    private authService: AuthenticationService,
    private _api: ApiService
  ) {
    this.user = this.storageService.read<any>('user');
  }

  public get items(): any[] {
    if (this.searchText) return this.searchedRecords;
    else return this.originalRecords;
  }

  ngOnInit() {
    this.getMerchantRecords(this.initialDate, 1)
  }

  // ngOnInit() {
  //   this.getLogs(this.initialDate, 1, this.start);
  //   this.getInflow(this.initialDate, 1, this.pagination.inflow.start);
  //   this.getOutflow(this.initialDate, 1, this.pagination.outflow.start);
  // }

  // getLogs(currentDate, page, start) {
  //   if (page === 1) this.isLoadingLog = true;
  //   else this.isFetchingLog = true;
  //   this.loansService.getBalances(start, currentDate)
  //     .subscribe(result => {
  //       const balances = result.balances.map(b => ({ ...b, merchant_name: this.getMerchantName(b.merchant_id, result.merchants.data) })).filter(res => res.full_name !== "")
  //       this.merchant_reports = page === 1 ? balances : [...this.merchant_reports, ...balances]
  //       this.merchants = result.merchants.data
  //       this.start = result.start
  //       this.isLoadingLog = false;
  //       this.isFetchingLog = false;
  //     }, err => {
  //       this.isLoadingLog = false;
  //       this.isFetchingLog = false;
  //     });
  // }

  // searchwithacnum() {
  //   if (this.bank_id == '1') {
  //     this.loansService.wemalogs(this.start, this.account_number)
  //       .subscribe(result => {
  //         this.data = result.data;

  //       }, err => {
  //       });
  //   }
  //   if (this.bank_id == '2') {

  //   }
  //   if (this.bank_id == '3') {

  //   }
  //   if (this.bank_id == '4') {

  //   }
  //   if (this.bank_id == '5') {

  //   }
  //   if (this.bank_id == '6') {

  //   }
  // }

  // getMerchantName(merchant_id, merchants) {
  //   let merchant_name = 'N/A';
  //   if (merchants && merchants.length) {
  //     merchants.forEach((element) => {
  //       if (element.id === merchant_id) {
  //         merchant_name = element.name;
  //       }
  //     });
  //   }
  //   return merchant_name;
  // }

  // async getInflow(currentDate, page, start) {
  //   try {
  //     if (page === 1) this.isLoadingInflow = true;
  //     else this.isFetchingInflow = true;
  //     const { balances, merchants: { data }, start: _start } = await this._api.getInflowSummary(start, currentDate)
  //     const result = balances.map(b => ({ ...b, full_name: this.getMerchantName(b.merchant_id, data) })).filter(res => res.full_name !== "")
  //     this.inflow_reports = page === 1 ? result : [...this.inflow_reports, ...result];
  //     this.pagination.inflow.start = _start
  //     this.isLoadingInflow = false;
  //     this.isFetchingInflow = false;
  //   } catch (error) {
  //     this.isLoadingInflow = false;
  //     this.isFetchingInflow = false;
  //   }
  // }

  // async getOutflow(currentDate, page, start) {
  //   console.log(page)
  //   try {
  //     if (page === 1) this.isLoadingOutflow = true;
  //     else this.isFetchingOutflow = true;
  //     const { balances, merchants: { data }, start: _start } = await this._api.getOutflowSummary(start, currentDate)
  //     const result = balances.map(b => ({ ...b, full_name: this.getMerchantName(b.merchant_id, data) })).filter(res => res.full_name !== "")
  //     this.outflow_reports = page === 1 ? result : [...this.outflow_reports, ...result];
  //     this.pagination.outflow.start = _start
  //     this.isLoadingOutflow = false;
  //     this.isFetchingOutflow = false;
  //   } catch (error) {
  //     this.isLoadingOutflow = false;
  //     this.isFetchingOutflow = false;
  //   }
  // }

  // filterMerchantReport({ start_date, end_date, page }) {
  //   this.startDate = start_date;
  //   this.endDate = end_date;
  //   this.filtered = true;
  //   this.getLogs({ start_date, end_date }, page, 0)
  // }

  // loadMoreMerchantReport(page) {
  //   const start_date = this.startDate || this.initialDate.start_date
  //   const end_date = this.endDate || this.initialDate.end_date
  //   this.getLogs({ start_date, end_date }, page, this.start)
  // }

  // filterInflowReport({ start_date, end_date, page }) {
  //   this.filtered = true;
  //   this.startDate = start_date;
  //   this.endDate = end_date;
  //   this.getInflow({ start_date, end_date }, page, 0)
  // }

  // loadMoreInflow(page) {
  //   const start_date = this.startDate || this.initialDate.start_date
  //   const end_date = this.endDate || this.initialDate.end_date
  //   this.getInflow({ start_date, end_date }, page, this.pagination.inflow.start)
  // }

  // filterOutflowReport({ start_date, end_date, page }) {
  //   this.startDate = start_date;
  //   this.endDate = end_date;
  //   this.filtered = true;
  //   this.getOutflow({ start_date, end_date }, page, 0)
  // }

  // loadMoreOutflow(page) {
  //   const start_date = this.startDate || this.initialDate.start_date
  //   const end_date = this.endDate || this.initialDate.end_date
  //   this.getOutflow({ start_date, end_date }, page, this.pagination.outflow.start)
  // }

  // onTabChange(event: any) {
  //   this.startDate = '';
  //   this.endDate = '';
  //   this.filtered = false;
  //   console.log('Selected tab index:', event);
  // }


  // filterMerchantReportByDuration({ value, page }) {
  //   console.log(value, page);
  //   if (value === 'duration') return;
  //   this.filtered = true;
  //   const date = value === 'today' ? this.today : this.initialDate
  //   this.getLogs(date, page, 0)
  // }

  // filterInflowReportByDuration({ value, page }) {
  //   console.log(value, page);
  //   if (value === 'duration') return;
  //   this.filtered = true;
  //   const date = value === 'today' ? this.today : this.initialDate
  //   this.getInflow(date, page, 0)
  // }

  // filterOutflowReportByDuration({ value, page }) {
  //   if (value === 'duration') return;
  //   console.log(value, page)
  //   this.filtered = true;
  //   const date = value === 'today' ? this.today : this.initialDate
  //   this.getOutflow(date, page, 0)
  // }

  async getMerchantRecords(payload, page) {
    try {
      if (page === 1) this.isLoading = true;
      else this.isFetching = true;
      const start = page * 20 - 20;
      const { data } = await this._api.getMerchantRecords({ ...payload, start });
      const sortingData = data.sort((a, b) => b.balance - a.balance);
      this.originalRecords = +page > 1 ? [...this.originalRecords, ...sortingData] : sortingData
      this.isLoading = false;
      this.isFetching = false;
    } catch (error) {
      this.isLoading = false;
      this.isFetching = false;
      console.log(error)
    }
  }

  filterRecordByDate({ start_date, end_date, page }) {
    this.filtered = true;
    this.startDate = start_date;
    this.endDate = end_date;
    this.detail_date = { start_date, end_date }
    this.getMerchantRecords({ start_date, end_date }, page)
  }

  filterByDuration({ value, page }) {
    if (value === 'duration') return;
    this.filtered = true;
    const date = value === 'today' ? this.today : this.initialDate
    this.detail_date = date;
    this.isToday = value === 'today' ? true : false;
    this.getMerchantRecords(date, page)
  }

  searchRecords() {
    this.searched = true;
    this.searchedRecords = this.originalRecords.filter(obj => obj.merchant_name !== null).filter((data) => {
      return data.merchant_name.toLowerCase().includes(this.searchText.toLowerCase());
    });
  }

  loadMore() {
    const start_date = this.startDate || this.initialDate.start_date
    const end_date = this.endDate || this.initialDate.end_date
    this.getMerchantRecords({ start_date, end_date }, ++this.pagination.page)
  }

  async getMerchant(merchant) {
    this.modals.view = 'open';
    this.isLoadingDetail = true;
    const { data: inflow } = await this._api.getTotalInflow(merchant.merchant_id)
    const { data: outflow } = await this._api.getTotalOutflow(merchant.merchant_id)
    this.isLoadingDetail = false;
    this.merchant_detail = { inflow, outflow, name: merchant.merchant_name }
    console.log(inflow, outflow)
  }

  formatNumber(value) {
    return formatNumber(+value)
  }


}
