import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wallet-operation',
  templateUrl: './wallet-operation.component.html',
  styleUrls: ['./wallet-operation.component.css']
})
export class WalletOperationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  onTabChange(event: any) {
    console.log('Selected tab index:', event);
  }


}
