import { Component, Input, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { LoansService } from 'src/app/_services/loans.service';
import { StorageService } from 'src/app/_services/storage.service';

@Component({
  selector: 'app-outflows',
  templateUrl: './outflows.component.html',
  styleUrls: ['./outflows.component.css']
})
export class OutflowsComponent implements OnInit {
  @Input() merchant_id = '';
  user:any;
  currentLoan=[];
  data:any;
  constructor(private storageService: StorageService, private loansService: LoansService, private authService: AuthenticationService) { 
    this.user = this.storageService.read<any>('user');
  }

  ngOnInit() {
    this.getCurrentLoans(this.merchant_id);
  }
  getCurrentLoans(request_id) {

    //this.loading = true; 
    this.loansService.getOutflows(request_id)
      .subscribe(result => {
         this.data = result;
      }, err => { 
      });
  }
  ngOnChanges(changes: SimpleChanges) {
    const request_id: SimpleChange = changes.merchant_id.currentValue;
    if (request_id) {
      this.getCurrentLoans(request_id)
    }
  }
}
