import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { LoansService } from 'src/app/_services/loans.service';

@Component({
  selector: 'app-sourcetransactions',
  templateUrl: './sourcetransactions.component.html',
  styleUrls: ['./sourcetransactions.component.css']
})
export class SourcetransactionsComponent implements OnInit {
  @Output() close = new EventEmitter();
  @Input() account_number = '';
  @Input() bank = '';
  startDate = '';
  endDate = '';
  wematransactions:any;
  constructor(private loansService: LoansService) { }

  ngOnInit() {
    this.getTransactions()
  }
  closeView() {
    this.close.emit();
  }
  getTransactions() {
    if (this.bank == 'wema') {
      this.loansService.getsourcewema(this.account_number)
        .subscribe(result => {
          this.wematransactions = result.transactions;
         
        }, err => {
        });
    }
  }
  getProvidusTransactions() {
    this.loansService.getProvidusTransactions(this.account_number, this.startDate, this.endDate)
      .subscribe(result => {

      }, err => {
      });
  }
}
