import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/_services/api.service";
import { GeneralService } from "src/app/_services/general.service";

@Component({
  selector: 'app-manage-referral',
  templateUrl: './manage-referral.component.html',
  styleUrls: ['./manage-referral.component.css']
})
export class ManageReferralComponent implements OnInit {

  constructor(private _api: ApiService, private _general: GeneralService) { }
  isLoading: boolean = false;
  isFetching: boolean = false;
  filtered: boolean = false;
  isConfirming: boolean = false;
  collections: Array<any> = [];
  collections_details = [];
  orderLists = [];
  modals = {
    view_detail: "",
  };
  isLoadingDetail = false;
  merchant_ids: Array<string> = [];
  message: string = "";
  phones: Array<any> = [];
  searchedCollections: any[] = [];
  searchText: string;
  searched: boolean = false;
  showCloseIcon: boolean = false;
  user = JSON.parse(localStorage.getItem("user")) || {};
  is_searching: boolean = false;
  totalCount: number = 0;
  pagination: any = {
    page: 1,
    end: false
  };

  ngOnInit() {
    this.getReferralOrders(this.pagination.page);
  }

  async getReferralOrders(page: number) {
    try {
      if (page === 1) this.isLoading = true;
      else this.isFetching = true;
      const { data, end } = await this._api.getReferralOrders({ page });
      this.collections = data.map((r: any) => ({ ...r, checked: false }));;
      this.pagination.end = end;
      this.isLoading = false;
      this.isFetching = false;
    } catch (error) {
      this.isLoading = false;
      this.isFetching = false;
      console.log(error);
    }
  }

  getItem({ item }) {
    this.getOrderDetails(item.id);
  }

  async getOrderDetails(id: number | string) {

    try {
      this.modals.view_detail = "open";
      this.isLoadingDetail = true;
      const { data } = await this._api.getReferralOrdersDetails({ id });
      this.collections_details = data;
      this.isLoadingDetail = false;
    } catch (error) {
      this.isLoading = false;
    }

  }
  async confirmReferralPayments() {
    try {
      if (!this.orderLists.length) return;
      this.isConfirming = true;
      const ids = this.orderLists.map(({ id }) => +id);
      const res = await this._api.confirmReferralPayments({ page: 1, ids });
      this.collections = this.collections.filter(item => !ids.includes(item.id));
      this.isConfirming = false;
      this.orderLists = [];
      this._general.notify({
        type: "success",
        message: `Payment confirmed!`,
        icon: "success",
      });
    } catch (error) {
      this.isConfirming = false;
      this._general.notify({
        type: "error",
        message: `Operation failed!`,
        icon: "error",
      });
    }

  }

  getcheckedCollections({ length, data }) {
    this.orderLists = [...this.orderLists, ...data].filter(
      (obj, index, self) =>
        index === self.findIndex((o) => o.id === obj.id)
    );
  }

  searchCollection() {
    this.searched = true;
    this.searchedCollections = this.collections.filter((data) => {
      return (
        data.fullname.toLowerCase().includes(this.searchText.toLowerCase()) ||
        data.phone.includes(this.searchText)
      );
    });
  }

  onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.showCloseIcon = !!input.value;
  }

  clearSearch(): void {
    this.searchText = "";
    const input = document.querySelector(".search") as HTMLInputElement;
    input.focus();
  }

  loadMore() {
    if (this.pagination.end) return;
    this.getReferralOrders(++this.pagination.page);
  }

}
