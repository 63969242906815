import { Component, OnInit, Input } from '@angular/core';
import { formatCurrency } from 'src/app/helpers';
import { IOrder } from 'src/app/models/request';

@Component({
  selector: 'app-manage-referral-detail-table',
  templateUrl: './manage-referral-detail-table.component.html',
  styleUrls: ['./manage-referral-detail-table.component.css']
})
export class ManageReferralDetailTableComponent implements OnInit {

  constructor() { }

  @Input() collections_details: IOrder[] = [];

  public get customer(): IOrder {
     return this.collections_details[0];
  }
  ngOnInit() {}
  formatCurrency(price: number) {
    return formatCurrency(price);
  }
}
