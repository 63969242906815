import { Component, OnInit, Input } from '@angular/core';
import { formatCurrency } from '../../helpers/index';

@Component({
  selector: 'app-request-list-card',
  templateUrl: './request-list-card.component.html',
  styleUrls: ['./request-list-card.component.css']
})
export class RequestListCardComponent implements OnInit {

  constructor() { }

  @Input() request: any = {};

  ngOnInit() {
    console.log(this.request);
  }

  getDisbursedStatus({ disbursed }) {
    if (disbursed) {
      return +disbursed > 0
    } else {
      return false
    }
  }

  getOfferStatus({ offer }) {
    if (offer) {
      return +offer > 0
    } else {
      return false
    }
  }

  formatCurrency(value) {
    return formatCurrency(+value);
  }

}
