import { Component, OnInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, } from '@angular/forms';
import { ApiService } from 'src/app/_services/api.service';
import { StoreService } from 'src/app/_services/store.service';
@Component({
  selector: 'app-disbursement',
  templateUrl: './disbursement.component.html',
  styleUrls: ['./disbursement.component.css']
})
export class DisbursementComponent implements OnInit {
  @ViewChild('fileInput', { static: false }) el: ElementRef;
  imageUrl: any = '/assets/dummy-user.jpg';
  new_disburse_form: FormGroup;
  is_loading: boolean = false;
  is_fetching: boolean = false;
  is_creating: boolean = false;
  is_approving: boolean = false;
  is_deleting: boolean = false;
  is_delete: boolean = false;
  loading_disburse_detail: boolean = false
  approve_started: boolean = false;
  delete_started: boolean = false;
  loading_pending_approval: boolean = false
  response_message: string = '';
  current_disbursement_id: string = '';
  disburses: Array<any> = [];
  wallets: Array<any> = [];
  disburse_items: Array<any> = [];
  pending_approvals: Array<any> = [];
  fileUrl: string = '/assets/staffonetofive (1).xlsx';
  pagination = {
    page: 1
  };
  modals = {
    new_disburse_form: '',
    preview_disburse: '',
    confirm: '',
    view_pending_approval: '',
  }

  constructor(
    private _api: ApiService,
    private store: StoreService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef
  ) {
    this.new_disburse_form = this.fb.group({
      'title': new FormControl('', [
        Validators.required,
      ]),
      'wallet_id': new FormControl('', [
        Validators.required,
      ]),
      'description': new FormControl('', [
        Validators.required,
      ]),
      'file': new FormControl(null, [
        Validators.required,
      ]),
    });

  }


  ngOnInit() {
    this.getWallets()
    this.getDisbursements(this.pagination.page)
  }

  get f() {
    return this.new_disburse_form.controls;
  }

  onFileChange(event) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      this.new_disburse_form.patchValue({
        file: file
      });
      // Clear the file input value
      const fileInput = event.target;
      fileInput.value = null;

    }
  }

  async getWallets() {
    try {
      const { data } = await this._api.getWallets();
      this.wallets = data;
      console.log(this.wallets);
    } catch (error) {
      console.log(error)
    }
  }

  async getDisbursements(page) {
    try {
      if (page === 1) this.is_loading = true;
      else this.is_fetching = true;
      const { data } = await this._api.getDisbursements();
      this.disburses = data;
      this.is_loading = false;
      this.is_fetching = false;
    } catch (error) {
      this.is_loading = false;
      this.is_fetching = false;
      console.log(error)
    }
  }

  async submitForm() {
    this.is_creating = true;
    const form_value = this.new_disburse_form.value
    const user = this.store.loggedInUser()
    const userId = user ? user.account_id : '';
    form_value['added_by'] = userId;
    const payload = new FormData()
    payload.append("wallet_id", this.new_disburse_form.get("wallet_id").value)
    payload.append("title", this.new_disburse_form.get("title").value)
    payload.append("description", this.new_disburse_form.get("description").value)
    payload.append("added_by", form_value.added_by)
    payload.append("file", this.new_disburse_form.get("file").value)

    try {
      const { status } = await this._api.createDisbursement(payload);
      if (status) {
        this.is_creating = false;
        this.response_message = 'success';
        this.new_disburse_form.reset()
        setTimeout(() => {
          this.response_message = '';
        }, 2000);
      } else {
        this.is_creating = false;
        this.response_message = 'error';
      }
    } catch (error) {
      this.is_creating = false;
      this.response_message = 'error';
    }
  }

  getPayload({ disburseId, type }) {
    this.current_disbursement_id = disburseId;
    if (type === 'delete') {
      this.is_delete = true;
      this.modals.confirm = 'open';
    } else {
      this.fetchDisbursementDetail(disburseId);
    }

  }

  async fetchDisbursementDetail(disburseId): Promise<void> {
    try {
      this.loading_disburse_detail = true;
      this.is_delete = false;
      this.modals.preview_disburse = 'open';
      const { data } = await this._api.getDisbursementDetail({ disbursement_id: disburseId })
      this.disburse_items = data
      this.loading_disburse_detail = false;
    } catch (error) {
      this.loading_disburse_detail = false;
      console.log(error)
    }
  }

  async initialtApproveDisbursement(): Promise<void> {
    try {
      this.is_approving = true;
      this.approve_started = true;
      const { status } = await this._api.initialtApproveDisbursement({ disbursement_id: this.current_disbursement_id })
      if (status) {
        this.is_approving = false;
        this.response_message = 'success';
        setTimeout(() => {
          this.response_message = '';
          this.modals.confirm = '';
          this.modals.preview_disburse = '';
        }, 2000);
      } else {
        this.is_approving = false;
        this.response_message = 'error';
      }
    } catch (error) {
      console.log(error);
      this.is_approving = false;
      this.response_message = 'error';
    }
  }

  getCheckedList(checkLists: any) {
    console.log(checkLists);
  }

  async deleteDisbursement() {
    try {
      this.is_deleting = true;
      this.delete_started = true;
      const { status } = await this._api.deleteDisbursement({ disbursement_id: this.current_disbursement_id })
      if (status) {
        this.disburses = this.disburses.filter(item => item.id !== this.current_disbursement_id)
        this.is_deleting = false;
        this.response_message = 'success';
        setTimeout(() => {
          this.response_message = '';
          this.modals.confirm = '';
          this.modals.preview_disburse = '';
        }, 2000);
      } else {
        this.is_deleting = false;
        this.response_message = 'error';
      }
    } catch (error) {
      console.log(error);
      this.is_deleting = false;
      this.response_message = 'error';
    }
  }



}
