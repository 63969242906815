import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationService } from "src/app/_services/authentication.service";
import { StorageService } from "src/app/_services/storage.service";

import Swal from "sweetalert2/dist/sweetalert2.js";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  resendWelcomeEmail = false;
  //create a model for the register form
  login_form = {
    email: "",
    password: "",
    grant_type: "password",
  };
  errorMessage = "";
  successMessage = "";
  loading = false;

  constructor(
    public router: Router,
    private storageService: StorageService,
    private authService: AuthenticationService
  ) { }

  ngOnInit() {
    this.authService.logout();
  }
  loginUser() {
    this.loading = true;
    this.authService.login(this.login_form).subscribe(
      (result) => {
        this.loading = false;
        localStorage.removeItem("user");
        if (result.account_id) {
          localStorage.setItem("user", JSON.stringify(result));
          const roles = (Array.isArray(result.roles) && result.roles.length) ? result.roles.map(u => u.toString()) : [];
          if (roles.includes('1') || roles.includes('1097')) {
            this.router.navigate(["/dashboard/merchants"]);
          } else if (roles.includes('3')) {
            this.router.navigate(["/dashboard/global-report"]);
          } else if (roles.includes('1095')) {
            this.router.navigate(["/dashboard/logs"]);
          } else if (roles.includes('1096') || roles.includes('1098')) {
            this.router.navigate(["/dashboard/staff-tracking"]);
          } else if (roles.includes('1100')) {
            this.router.navigate(["/dashboard/staff-report"]);
          } else {
            this.router.navigate(["/dashboard/merchants"]);
          }
        } else {
          Swal.fire({
            title: "Ooops! You don't have an account",
            text: result.message,
            icon: "error",
          });
        }
      },
      (err) => {
        console.log(err);
        Swal.fire({
          title: "Ooops! Unable to login",
          icon: "error",
        });
      }
    );
  }
}
