import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { StorageService } from 'src/app/_services/storage.service';

import Swal from 'sweetalert2/dist/sweetalert2.js';


@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit {

  resendWelcomeEmail = false;
  //create a model for the register form
  login_form = {
    'EMAIL': '',
    'password': ''
  }
  errorMessage = '';
  successMessage = '';
  loading = false;

  constructor(public router: Router, private storageService: StorageService, private authService: AuthenticationService) { }

  ngOnInit() {
    this.authService.logout();
  }
  tryLogin(value) {
    this.loading = true;
    this.authService.forgot(value)
      .subscribe(result => {
        this.loading = false;
        localStorage.removeItem('user');
        if (result.status == true) { 
          Swal.fire({
            title: 'Success',
            text: result.message,
            icon: 'success'
          });
          this.router.navigate(['/login']);
        } else {
          Swal.fire({
            title: 'Ooops',
            text: result.message,
            icon: 'error'
          });
        }


      }, err => {
      });
  }
}
