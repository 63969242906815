import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/_services/api.service';
import { IsValidJson, currentDate, getToday, previousMonth } from 'src/app/helpers';

@Component({
  selector: 'app-commission',
  templateUrl: './commission.component.html',
  styleUrls: ['./commission.component.css']
})
export class CommissionComponent implements OnInit {

  constructor(private _api: ApiService) { }

  commission_reports: Array<any> = [];
  commission_detail: any = null;
  current_report: any = null;
  selected_date: null;
  run_commission: any = { commissions: [], last_payment: '' };
  startDate = '';
  endDate = '';
  searchedStaffs: any[] = [];
  detail_date: Object = currentDate();
  searchText: string = '';
  initialDate = currentDate();
  today = getToday();
  lastMonth = previousMonth();
  reports: string[] = [];
  isLoading: boolean = false;
  isFetching: boolean = false;
  loading: boolean = false;
  isLoading1: boolean = false;
  isFetching1: boolean = false;
  isLoading2: boolean = false;
  isFetching2: boolean = false;
  filtered: boolean = false;
  searched: boolean = false;
  isToday: boolean = false;
  isLoadingCommission: boolean = false;
  isLoadingAllCommission: boolean = false;
  sort_order: string = 'merchant count';
  current_staff: any = {};
  region_report: any = { coordinator: '', staff_count: 0, merchant_count: 0 };
  staff_reports: Array<any> = [];
  durations: Array<object> = [
    { title: 'Duration', value: 'duration' },
    { title: 'Today', value: 'today' },
    { title: 'This Month', value: 'this month' },
    { title: 'Last Month', value: 'last month' }
  ];
  modals = {
    view: '',
    commissions: '',
  };

  response_commissions: any = '';



  ngOnInit() {
    this.getCommissionReport()
    this.getStaffReport(this.initialDate, 1)
  }

  public get staffs(): any[] {
    if (this.searchText) return this.searchedStaffs;
    else return this.staff_reports;
  }


  async runCommission() {
    try {
      this.isLoading2 = true;
      const { data: { data: res, last_date_paid } } = await this._api.getCommission()
      this.run_commission = { commissions: res, last_payment: last_date_paid };
      console.log(this.run_commission);
      this.isLoading2 = false;
    } catch (error) {
      this.isLoading2 = false;
      console.log(error)
    }
  }

  async getCommissionReport() {
    try {
      this.isLoading1 = true;
      const { data: { data: reports } } = await this._api.getCommissionReport()
      console.log(reports)
      this.commission_reports = this.groupReport(reports)
      console.log(this.commission_reports)
      this.current_report = this.commission_reports[0];
      console.log(this.current_report)
      this.selected_date = this.current_report.id;
      console.log(this.selected_date)
      this.isLoading1 = false;
    } catch (error) {
      this.isLoading1 = false;
      console.log(error)
    }
  }

  filterReport(event) {
    this.current_report = this.commission_reports.find(r => r.id === +event.target.value);
    console.log(this.current_report.result)
  }

  groupReport(reports) {
    if (reports && reports.length) {
      return reports.map(report => ({ ...report, result: IsValidJson ? JSON.parse(report.result) : report.result }))
    } else return []
  }

  onTabChange(event: any) {
    this.startDate = '';
    this.endDate = '';
    this.filtered = false;
    console.log('Selected tab index:', event);
  }

  async getStaffReport(payload, page, isRegion = false) {
    try {
      if (page === 1) this.isLoading = true;
      else this.isFetching = true;
      const { data } = await this._api.getStaffReport(payload);
      const activeStaff = data.filter(staff => +staff.onboarded_merchants > 0);
      if (isRegion) {
        this.staff_reports = activeStaff.affiliates_results.map(r => ({ ...r, checked: false }))
        this.region_report = {
          coordinator: activeStaff.regional_head.full_name,
          staff_count: activeStaff.total_staff,
          merchant_count: activeStaff.total_onboarded_merchants
        }
      }
      else this.staff_reports = activeStaff.map(r => ({ ...r, checked: false }))
      this.isLoading = false;
      this.isFetching = false;
    } catch (error) {
      this.isLoading = false;
      this.isFetching = false;
      console.log(error)
    }
  }

  sortStaff() {
    if (this.sort_order === 'merchant count') {
      this.staff_reports = this.staff_reports.sort((a, b) => a.full_name.localeCompare(b.full_name))
      this.sort_order = 'full_name';
    } else {
      this.staff_reports = this.staff_reports.sort((a, b) => b.onboarded_merchants - a.onboarded_merchants);
      this.sort_order = 'merchant count'
    }
  }

  searchStaff() {
    this.searched = true;
    this.searchedStaffs = this.staff_reports.filter((data) => {
      return data.full_name.toLowerCase().includes(this.searchText.toLowerCase()) || data.mobile.includes(this.searchText);
    });
  }

  filterByDuration({ value, page }) {
    if (value === 'duration') return;
    this.filtered = true;
    const date = value === 'today' ? this.today : value === 'this month' ? this.initialDate : this.lastMonth
    this.isToday = value === 'today' ? true : false;
    this.detail_date = date;
    this.getStaffReport(date, page)
  }

  filterStaff({ start_date, end_date, page }) {
    this.filtered = true;
    this.startDate = start_date;
    this.endDate = end_date;
    this.detail_date = { start_date, end_date }
    this.getStaffReport({ start_date, end_date }, page)
  }

  getItem({ staff }) {
    console.log(staff)
    this.current_staff = staff
    this.modals.view = 'open';
    this.getCommissionByDate(staff.staff_id)
  }

  async getCommissionByDate(staff_id) {
    try {
      this.isLoadingCommission = true;
      const { data } = await this._api.getCommissionByDate({ staff_id, date: this.detail_date });
      this.commission_detail = data;
      this.isLoadingCommission = false;
      console.log(this.commission_detail);

    } catch (error) {

    }
  }
  async getCommissionByDateStaff() {
    try {
      this.isLoadingAllCommission = true;
      this.modals.commissions = 'open';
      const { data } = await this._api.getCommissionByDateStaff({ records: this.staff_reports, ...this.detail_date });
      console.log(data);
      this.response_commissions = 'data';
      this.isLoadingAllCommission = false;

      setTimeout(() => {
        this.modals.commissions = '';
      }, 4000)

    } catch (error) {
      this.response_commissions = '';
      console.log(error);
    }
  }

}
