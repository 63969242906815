import { Component, OnInit, Input } from '@angular/core';
import { formatNumber } from 'src/app/helpers';
// import { formatNumber } from 'src/helpers';

@Component({
  selector: 'app-outflow-summary',
  templateUrl: './outflow-summary.component.html',
  styleUrls: ['./outflow-summary.component.css']
})
export class OutflowSummaryComponent implements OnInit {

  constructor() { }

  @Input() outflow_reports = [];

  ngOnInit() { console.log(this.outflow_reports) }

  formatNumber(value) {
    return formatNumber(+value)
  }

}
