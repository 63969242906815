import { Component, OnInit, Input } from '@angular/core';
import { formatCurrency } from 'src/app/helpers';

@Component({
  selector: 'app-wallet-list-table',
  templateUrl: './wallet-list-table.component.html',
  styleUrls: ['./wallet-list-table.component.css']
})
export class WalletListTableComponent implements OnInit {

  constructor() { }

  @Input() wallets = [];

  ngOnInit() {

  }

  formatCurrency(value) {
    return formatCurrency(+value);
  }

}
