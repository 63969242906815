import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ApiService } from "src/app/_services/api.service";
import { GeneralService } from "src/app/_services/general.service";
import {
  currentDate,
  previousMonth,
  formatCurrency,
  getToday,
  formatStaffAlphabetically,
  checkAccountAvailability,
} from "src/app/helpers";
import Swal from "sweetalert2/dist/sweetalert2.js";

@Component({
  selector: "app-staff-tracking",
  templateUrl: "./staff-tracking.component.html",
  styleUrls: ["./staff-tracking.component.css"],
})
export class StaffTrackingComponent implements OnInit {
  constructor(private _api: ApiService, private _general: GeneralService) { }
  isLoading: boolean = false;
  isFetching: boolean = false;
  filtered: boolean = false;
  isToday: boolean = false;
  isSendingMessage: boolean = false;
  loadingBVN: boolean = false;
  staff_reports: Array<any> = [];
  merchant_list: Array<any> = [];
  merchant_list_detail = null;
  startDate = "";
  endDate = "";
  initialDate = currentDate();
  today = getToday();
  lastMonth = previousMonth();
  modals = {
    merchant_list: "",
    merchant_list_detail: "",
    broadcast_message: "",
    update_staff: "",
    search_merchant: "",
  };
  isLoadingDetail = false;
  merchant_ids: Array<string> = [];
  current_staff = {};
  current_merchant = {};
  message: string = "";
  phones: Array<any> = [];
  durations: Array<object> = [
    { title: "Duration", value: "duration" },
    { title: "Today", value: "today" },
    { title: "This Month", value: "this month" },
    { title: "Last Month", value: "last month" },
  ];
  regions: Array<any> = [
    { title: "Region", value: "0" },
    { title: "Region 1", value: "1" },
    { title: "Region 2", value: "2" },
    { title: "Region 3", value: "3" },
    { title: "Region 4", value: "4" },
    { title: "Region 5", value: "5" },
    { title: "Region 6", value: "6" },
    { title: "Region 7", value: "7" },
    { title: "Region 8", value: "8" },
    { title: "Region 9", value: "9" },
    { title: "Region 10", value: "10" },
    { title: "Region 11", value: "11" },
    { title: "Region 12", value: "12" },
    { title: "Region 13", value: "13" },
    { title: "Region 14", value: "14" },
    { title: "Region 15", value: "15" },
  ];
  team_lead_option = [
    { title: "None", value: "-1" },
    { title: "Yes", value: "1" },
    { title: "No", value: "0" },
  ];
  staff_detail = {};
  selected_region: any = this.regions[0].value;
  address: any = "";
  team_leader_phone: any = "";
  selected_team_lead_status: any = this.team_lead_option[0].value;
  isUpdating: boolean = false;
  isDeactivating: boolean = false;
  teamLeadNotFound: any = "";
  active_item: any;
  region_report: any = { coordinator: "", staff_count: 0, merchant_count: 0 };
  staff_phone: string;
  sort_order: string = "merchant count";
  searchedStaffs: any[] = [];
  searchText: string;
  searched: boolean = false;
  showCloseIcon: boolean = false;
  user = JSON.parse(localStorage.getItem("user")) || {};
  is_searching: boolean = false;
  search_merchant: string = "";
  searchedMerchants: any[] = [];
  is_searchedMerchant: boolean = false;

  public get staffs(): any[] {
    if (this.searchText) return this.searchedStaffs;
    else return this.staff_reports;
  }

  ngOnInit() {
    this.getStaffReport(this.initialDate, 1);
  }

  async getStaffReport(payload, page, isRegion = false) {
    try {
      if (page === 1) this.isLoading = true;
      else this.isFetching = true;
      const { data } = await this._api.getStaffReport(payload);
      if (isRegion) {
        this.staff_reports = data.affiliates_results.map((r) => ({
          ...r,
          checked: false,
        }));
        this.region_report = {
          coordinator: data.regional_head.full_name,
          staff_count: data.total_staff,
          merchant_count: data.total_onboarded_merchants,
        };
      } else this.staff_reports = data.map((r) => ({ ...r, checked: false }));
      this.isLoading = false;
      this.isFetching = false;
    } catch (error) {
      this.isLoading = false;
      this.isFetching = false;
      console.log(error);
    }
  }

  filterStaff({ start_date, end_date, page }) {
    this.filtered = true;
    this.startDate = start_date;
    this.endDate = end_date;
    this.getStaffReport({ start_date, end_date }, page);
  }

  getItem({ item, type }) {
    if (type === "view") {
      this.viewMerchantList(item);
    }
    if (type === "update") {
      this.updateStaffInfo(item);
    }
    if (type === "deactivate") {
      this.deactivateStaff(item);
    }
  }

  async viewMerchantList(payload: any) {
    const {
      mobile: phone,
      onboarded_merchants: merchant_count,
      full_name,
      merchant_ids,
    } = payload;
    this.current_staff = { full_name, merchant_count };
    try {
      this.modals.merchant_list = "open";
      this.modals.update_staff = "";
      this.isLoading = true;
      const { start_date, end_date } = this.initialDate;
      const is_today =
        this.isToday ||
        (start_date === this.startDate && end_date === this.endDate);
      const startDate = this.startDate || start_date;
      const endDate = this.endDate || end_date;
      const {
        data: { onboarded_merchants_count, onboarded_merchants, merchant_ids },
      } = await this._api.getMerchantList(phone, is_today, startDate, endDate);
      this.merchant_ids = merchant_ids;
      this.merchant_list = onboarded_merchants;
      // this.merchant_list = onboarded_merchants.sort((a, b) => a.OWNER.localeCompare(b.OWNER))
      this.isLoading = false;
      this.isToday = false;
    } catch (error) {
      console.log(error);
      this.isLoading = false;
    }
  }

  async getMerchantDetail(merchants: any) {
    if (Array.isArray(merchants) && merchants.length > 0) {
      const promises = merchants.reduce((acc: any, merchant: any) => {
        acc.push(
          new Promise((resolve, reject) => {
            this._api
              .getMerchantListDetail({ merchant_id: [merchant.id], period: 2 })
              .then(({ data }) => resolve({ ...merchant, ...data }))
              .catch((err) => reject(err));
          })
        );
        return acc;
      }, []);
      return await Promise.all(promises);
    } else {
      return [];
    }
  }

  async viewMerchantListDetail(payload) {
    try {
      const { OWNER: full_name, address, phone, id } = payload;
      this.current_merchant = { full_name, address, phone };
      this.modals.merchant_list_detail = "open";
      this.isLoadingDetail = true;
      const { data } = await this._api.getMerchantListDetail({
        merchant_id: [id],
        period: 1,
      });
      this.merchant_list_detail = data;
      this.isLoadingDetail = false;
    } catch (error) {
      this.isLoading = false;
    }
  }

  filterByDuration({ value, page }) {
    if (value === "duration") return;
    this.filtered = true;
    const date =
      value === "today"
        ? this.today
        : value === "this month"
          ? this.initialDate
          : this.lastMonth;
    this.isToday = value === "today" ? true : false;
    this.getStaffReport(date, page);
  }

  getCheckedStaffList({ length, data }) {
    this.phones = [...this.phones, ...data].filter(
      (obj, index, self) =>
        index === self.findIndex((o) => o.mobile === obj.mobile)
    );
  }

  async sendBroadcasMessage() {
    if (!this.phones.length || !this.message) return;
    try {
      this.isSendingMessage = true;
      const phones = this.phones.map(({ mobile }) => mobile);
      const { status } = await this._api.sendBroadcastMessage({
        phones,
        message: this.message,
      });
      this.isSendingMessage = false;
      if (status) {
        this.phones = [];
        this.message = "";
        this.modals.broadcast_message = "";
        this._general.notify({
          type: "Success",
          message: "message sent successfully",
          icon: "success",
        });
        if (this.searched) {
          this.searchedStaffs = this.searchedStaffs.map((r) => ({
            ...r,
            checked: false,
          }));
        } else {
          this.staff_reports = this.staff_reports.map((r) => ({
            ...r,
            checked: false,
          }));
        }
      } else {
        this._general.notify({
          type: "Error",
          message: "unable to send message",
          icon: "error",
        });
      }
    } catch (error) {
      this._general.notify({
        type: "Error",
        message: "unable to send message",
        icon: "error",
      });
      console.log(error);
    }
  }

  filterByRegions({ value, page }) {
    if (value === "0") return;
    this.filtered = true;
    const start_date = this.startDate || this.initialDate.start_date;
    const end_date = this.endDate || this.initialDate.end_date;
    this.getStaffReport({ start_date, end_date, region: +value }, page, true);
  }

  updateStaffInfo({ is_team_lead, region, mobile }) {
    if (this.isRO()) return;
    this.modals.merchant_list = "";
    this.staff_phone = mobile;
    this.selected_region = region ? `${region}` : this.regions[0].value;
    this.selected_team_lead_status = is_team_lead
      ? "1"
      : this.team_lead_option[2].value;
    this.modals.update_staff = "open";
  }

  async submitUpdatedStaff() {
    if (this.isRO()) return;
    try {
      this.isUpdating = true;
      console.log(this.staff_phone);
      const { status, team_lead_id: agent_id } = await this.getTeamLead(
        this.staff_phone
      );
      console.log(agent_id);
      if (this.team_leader_phone && agent_id) {
        const { status, team_lead_id } = await this.getTeamLead(
          this.team_leader_phone
        );
        if (!status) {
          this.teamLeadNotFound = `No team lead found with ${this.team_leader_phone}`;
          this.isUpdating = false;
        } else {
          this.teamLeadNotFound = "";
          const address = this.address ? { address: this.address } : {};
          const is_team_lead =
            this.selected_team_lead_status !== "-1"
              ? { is_team_lead: this.selected_team_lead_status }
              : {};
          const region =
            this.selected_region !== "0"
              ? { region: this.selected_region }
              : {};
          const payload = {
            ...address,
            ...region,
            team_lead: team_lead_id,
            ...is_team_lead,
          };
          const { status } = await this._api.updateStaffInfo(payload, agent_id);
          if (status) {
            this._general.notify({
              type: "Success",
              message: `updated`,
              icon: "success",
            });
            this.isUpdating = false;
            this.modals.update_staff = "";
          } else {
            this._general.notify({
              type: "Error",
              message: `update failed`,
              icon: "error",
            });
            this.isUpdating = false;
          }
        }
      }
      if (!this.team_leader_phone && agent_id) {
        const address = this.address ? { address: this.address } : {};
        const is_team_lead =
          this.selected_team_lead_status !== "-1"
            ? { is_team_lead: this.selected_team_lead_status }
            : {};
        const region =
          this.selected_region !== "0" ? { region: this.selected_region } : {};
        const payload = {
          ...address,
          ...region,
          ...is_team_lead,
        };
        const { status } = await this._api.updateStaffInfo(payload, agent_id);
        if (status) {
          this._general.notify({
            type: "Success",
            message: `updated`,
            icon: "success",
          });
          this.isUpdating = false;
          this.modals.update_staff = "";
        } else {
          this._general.notify({
            type: "Error",
            message: `update failed`,
            icon: "error",
          });
          this.isUpdating = false;
        }
      }
    } catch (error) {
      this.isUpdating = false;
      this.teamLeadNotFound = "Update failed";
    }
  }

  async deactivateStaff(payload: any) {
    if (this.isRO()) return;
    try {
      this.active_item = payload.mobile;
      this.isDeactivating = true;
      const { status, team_lead_id: agent_id } = await this.getTeamLead(
        payload.mobile
      );
      if (!status) {
        this.isDeactivating = false;
        this._general.notify({
          type: "Error",
          message: `deactivation failed`,
          icon: "error",
        });
        return;
      }
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to activate this staff again!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, deactivate it!",
        cancelButtonText: "No, keep it",
      }).then(async (result) => {
        if (result.value) {
          console.log(agent_id);
          const res = await this._api.updateStaffInfo(
            { deactivate: true },
            agent_id
          );
          console.log(res);
          this.staff_reports = this.staff_reports.filter(
            (r) => r.mobile !== payload.mobile
          );
          Swal.fire(
            "Deactivated!",
            "The Staff has been deactivated successfully.",
            "success"
          );
          this.isDeactivating = false;
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          this.isDeactivating = false;
          Swal.fire("Cancelled", "The staff is safe", "error");
        }
      });
    } catch (error) {
      this.isDeactivating = false;
      this._general.notify({
        type: "Error",
        message: `Oop, no agent found!`,
        icon: "error",
      });
    }
  }

  async getTeamLead(phone) {
    try {
      const res = await this._api.getTeamLead(phone);
      if (res.status) return { status: true, team_lead_id: res.data.id };
      else return { status: false, team_lead_id: null };
    } catch (error) {
      console.log(error);
    }
  }

  sortStaff() {
    if (this.sort_order === "merchant count") {
      // this.staff_reports = formatStaffAlphabetically(this.staff_reports)
      this.staff_reports = this.staff_reports.sort((a, b) =>
        a.full_name.localeCompare(b.full_name)
      );
      this.sort_order = "full_name";
    } else {
      this.staff_reports = this.staff_reports.sort(
        (a, b) => b.onboarded_merchants - a.onboarded_merchants
      );
      this.sort_order = "merchant count";
    }
  }

  searchStaff() {
    this.searched = true;
    this.searchedStaffs = this.staff_reports.filter((data) => {
      return (
        data.full_name.toLowerCase().includes(this.searchText.toLowerCase()) ||
        data.mobile.includes(this.searchText)
      );
    });
  }

  onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.showCloseIcon = !!input.value;
  }

  clearSearch(): void {
    this.searchText = "";
    const input = document.querySelector(".search") as HTMLInputElement;
    input.focus();
  }

  isRO() {
    const user_roles =
      Array.isArray(this.user.roles) && this.user.roles.length
        ? this.user.roles.map((u) => u.toString())
        : [];
    if (user_roles.length === 1 && user_roles[0] === "1098") {
      return true;
    }
    return false;
  }

  async searchMerchant() {
    try {
      this.is_searching = true;
      this.is_searchedMerchant = true;
      const {
        data: { data: merchants },
      } = await this._api.searchMerchant({ search: this.search_merchant });
      const merchantDetail = await this.getMerchantDetail(merchants);
      this.searchedMerchants = await this.addBVNDetail(merchantDetail);
      console.log(this.searchedMerchants);
      this.is_searching = false;
    } catch (error) {
      console.log(error);
    }
  }

  checkAccounts(data: any) {
    return checkAccountAvailability(data);
  }

  async addBVNDetail(merchants: any[]) {
    if (Array.isArray(merchants) && merchants.length > 0) {
      const promises = merchants.reduce((acc: any, request: any) => {
        if (!request.phone) acc.push(Promise.resolve(request));
        else {
          acc.push(
            new Promise((resolve, reject) => {
              this._api.getBVNDetail(request.phone)
                .then(({ data }) =>
                  resolve({
                    ...request,
                    bvn: data,
                  })
                )
                .catch((err: any) => reject(err));
            })
          );
        }
        return acc;
      }, []);
      return await Promise.all(promises);
    } else {
      return merchants;
    }
  }

}
