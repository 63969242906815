import { Injectable } from '@angular/core'; 
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': 'OBAUS1aYGx4YQFhWymCXgLK7rXpmoLv3gtj9ufi8N8wcVxCMvP5RVb9Wp5Bzcn0d' })
};

@Injectable({
  providedIn: 'root'
})
export class SavingsService {
  constructor(private http: HttpClient) { }

  getCurrentInvestments(data: object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/investment/snapshot', data, httpOptions);
  }
  getWalletSnapsot(data: object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/wallet/snapshot', data, httpOptions);
  }
  geSavingsPortfolio(data: object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/savings/portfolio', data, httpOptions);
  } 
  getSavingsHistory(data: object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/savings/history', data, httpOptions);
  }
  getInvestment(data: object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/savings/plan', data, httpOptions);
  }
  getWalletDetails(data: object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/wallet/snapshot', data, httpOptions);
  }
  apply(data: object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/apply_for_savings_flutterwave', data, httpOptions);
  }
  chargeCard(payload:object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/flutterwave/charge/card', payload, httpOptions);
  }
  quicksave(payload:object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/flutterwave/quicksave', payload, httpOptions);
  }
  quicksavefromwallet(payload:object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/quicksave/wallet', payload, httpOptions);
  }
  rollover(payload:object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/user/savings/savingsrollover', payload, httpOptions);
  }
  withdraw(payload:object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/user/savings/savingswithdraw', payload, httpOptions);
  }
  walletwithdraw(payload:object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/user/wallet/walletwithdraw', payload, httpOptions);
  }
  quickSaveNewCard(payload:object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/flutterwave/quicksave_newcard', payload, httpOptions);
  }
  fundwalletNewCard(payload:object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/flutterwave/fundwallet_newcard', payload, httpOptions);
  }
  fundwallet(payload:object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/flutterwave/fundwallet', payload, httpOptions);
  }
  
}
 