import { Injectable,EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor() { }
  openSideBar: EventEmitter<any> = new EventEmitter();
  showSpinner: EventEmitter<any> = new EventEmitter();
  hideSpinner: EventEmitter<any> = new EventEmitter();
  relogin: EventEmitter<any> = new EventEmitter();
  sendMerchant: EventEmitter<any> = new EventEmitter();
  openHistory: EventEmitter<any> = new EventEmitter();
}
