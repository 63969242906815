import { Injectable } from '@angular/core'; 
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': 'OBAUS1aYGx4YQFhWymCXgLK7rXpmoLv3gtj9ufi8N8wcVxCMvP5RVb9Wp5Bzcn0d' })
};

@Injectable({
  providedIn: 'root'
})
export class InvestmentsService {

  constructor(private http: HttpClient) { }

  getCurrentInvestments(data: object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/investment/snapshot', data, httpOptions);
  }
  getWalletSnapsot(data: object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/wallet/snapshot', data, httpOptions);
  }
  geInvestmentPortfolio(data: object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/investment/portfolio', data, httpOptions);
  }
  getInvestmentHistory(data: object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/investment/history', data, httpOptions);
  }
  getInvestmentPending(data: object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/investment/pending', data, httpOptions);
  }
  getInvestment(data: object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/investment/details', data, httpOptions);
  }
  apply(data: object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/lender/apply_flutterwave', data, httpOptions);
  }
  liquidate(payload:object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/user/investments/liquidate', payload, httpOptions);
  }
  terminate(payload:object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/user/investments/terminate', payload, httpOptions);
  }
  rollover(payload:object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/user/investments/rollover', payload, httpOptions);
  }
  topup(payload:object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/user/investments/topup', payload, httpOptions);
  }
}
 