import { Component, Input, OnInit } from '@angular/core';
// import { ApiService } from 'src/app/services/api.service';
// import { GeneralService } from 'src/app/services/general.service';
// import { timeout } from 'rxjs/operators';
// import { lastValueFrom, TimeoutError } from 'rxjs';
// import { AuthService } from 'src/app/services/auth.service';
import { ApiService } from 'src/app/_services/api.service';
import { IRequest } from 'src/app/models/request';
import { formatCurrency, formatNumber } from '../../helpers/index';

@Component({
  selector: 'app-store-order-details',
  templateUrl: './store-order-details.component.html',
  styleUrls: ['./store-order-details.component.css']
})
export class StoreOrderDetailsComponent implements OnInit {

  @Input() request: IRequest | any;
  mobile = window.innerWidth < 768;

  constructor(
    private _api: ApiService
  ) { }

  ngOnInit(): void {
  }

  formatCurrency(currency: any) {
    return formatCurrency(+currency)
  }

  formatNumber(number: any) {
    return formatNumber(+number)
  }

  getTotalAmount(amount, qty) {
    const total = (+amount) * (+qty);
    return formatCurrency(total);
  }
}
