import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/_services/api.service';
import { StoreService } from 'src/app/_services/store.service';
import { currentDate, formatCurrency } from 'src/app/helpers';

@Component({
  selector: 'app-disburse-approvals',
  templateUrl: './disburse-approvals.component.html',
  styleUrls: ['./disburse-approvals.component.css']
})
export class DisburseApprovalsComponent implements OnInit {

  is_approving: boolean = false;
  is_loading: boolean = false;
  loading_approval_detail: boolean = false;
  loading_approved_item: boolean = false;
  approve_started: boolean = false;
  current_approval_id = null;
  response_message: string = '';
  approved_sum: string = '';
  pending_approvals: Array<any> = [];
  approval_items: Array<any> = [];
  approved_items: Array<any> = [];
  checkedItems: Array<any> = [];
  initialDate = currentDate();
  modals = {
    confirm: '',
    preview_approval: '',
    preview_approved: '',
    disburse_category: '',
  }

  constructor(
    private _api: ApiService,
    private store: StoreService,
  ) { }


  ngOnInit() {
    this.getDisburseApprovals(this.initialDate)
  }

  async getDisburseApprovals(payload): Promise<void> {
    try {
      this.is_loading = true;
      const user = this.store.loggedInUser()
      const userId = user ? user.account_id : '';
      const { data } = await this._api.getDisburseApprovals(payload)
      this.approved_sum = this.sumApprovedSum(data)
      this.pending_approvals = data
      this.is_loading = false;
    } catch (error) {
      this.is_loading = false;
      console.log(error)
    }
  }

  // async getItemId(Id): Promise<void> {
  // this.current_approval_id = Id;
  // this.modals.confirm = 'open';
  // this.approve_started = true;
  // }

  async getPayload({ approval_id, disbursement_id, type }): Promise<void> {
    try {
      this.current_approval_id = approval_id;
      this.loading_approval_detail = true;
      if (type === 'all') this.modals.preview_approval = 'open';
      if (type === 'approved-item') this.modals.preview_approved = 'open';
      if (type === 'pending') {
        const { data } = await this._api.getDisbursementDetailPending({ disbursement_id: disbursement_id })
        this.approval_items = data
      }
      if (type === 'approved') {
        const { data } = await this._api.getDisbursementDetailApproved({ disbursement_id: disbursement_id })
        this.approval_items = data
      }
      if (type === 'approved-item') {
        const { data } = await this._api.getDisbursementApproved({ disbursement_id: disbursement_id, is_approved: "1" })
        this.approved_items = data
      }
      this.loading_approval_detail = false;
    } catch (error) {
      this.loading_approval_detail = false;
      console.log(error)
    }
  }



  async approveDisbursement(): Promise<void> {
    try {
      this.is_approving = true;
      this.approve_started = true;
      const { data, status } = await this._api.approveDisbursement({ pending_approval_id: this.current_approval_id, data: this.checkedItems })
      if (status) {
        this.is_approving = false;
        this.response_message = 'success';
        setTimeout(() => {
          this.response_message = '';
          this.modals.confirm = '';
        }, 2000);
      } else {
        this.is_approving = false;
        this.response_message = 'error';
      }
    } catch (error) {
      this.is_approving = false;
      this.response_message = 'error';
      console.log(error)
    }
  }

  getCheckedList(checkLists: any) {
    this.checkedItems = checkLists.data
    console.log(checkLists);
  }

  filterDisburseApproval({ start_date, end_date, page }) {
    this.getDisburseApprovals({ start_date, end_date })
  }

  sumApprovedSum(data) {
    let sum = 0;
    for (const item of data) {
      if (item.approved_sum !== null) {
        sum += parseInt(item.approved_sum, 10);
      }
    }
    return formatCurrency(+sum);
  }


}
