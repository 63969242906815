import { Injectable } from '@angular/core'; 
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tokenName } from '@angular/compiler';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': 'OBAUS1aYGx4YQFhWymCXgLK7rXpmoLv3gtj9ufi8N8wcVxCMvP5RVb9Wp5Bzcn0d' })
};

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpClient) { }

  getUserBanks(data: object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/accounts', data, httpOptions);
  }
  getUserCards(data: object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/cards/flutterwave', data, httpOptions);
  }
  getRecentTransactions(data: object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/recent_transactions', data, httpOptions);
  }
  addANewCard(data: object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/flutterwave/add/newcard', data, httpOptions);
  }
  addANewCards(data: object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/flutterwave/add/newcards', data, httpOptions);
  }
  validateAccountBank(data: object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/webapi/v1/account/resolve', data, httpOptions);
  }
  validateBVN(data: object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/webapi/v1/verify_bvn_dob', data, httpOptions);
  }
  chargeCard(payload:object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/flutterwave/charge/card', payload, httpOptions);
  }
  chargeCardOnly(payload:object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/flutterwave/charge/card/only', payload, httpOptions);
  }
  chargeCardOnlys(payload:object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/flutterwave/charge/card/onlys', payload, httpOptions);
  }
  addBank(data: any, token:any): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/banks/save', {token:token, account:data}, httpOptions);
  }
  changePassword(data: any, token:any): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/user/change_password', {token:token, account:data}, httpOptions);
  }
  updateProfilePicture(payload:object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/update/picture', payload, httpOptions);
  }
  savedata(data:any){
    const promise = new Promise((resolve, reject) => {
      this.http.post('https://mobile.creditclan.com/api/v3/customer/save_datas', data, httpOptions)
        .toPromise()
        .then(
          res => { // Success
            resolve();
          }
        );
    });
    return promise;
  }
  updateProfile(data: object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/save_datas', data, httpOptions);
  }
  getUserProfile(data: object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/user/details', data, httpOptions);
  }
  getCountries(): Observable<any> {
    // get users from api
    return this.http.get('assets/country.json'); 
  }
  getQualifications(): Observable<any> {
    // get users from api
    return this.http.get('assets/education.json'); 
  }
  getLoanDurationns(): Observable<any> {
    // get users from api
    return this.http.get('assets/suration.json'); 
  }
  getOcc(): Observable<any> {
    return this.http.get('assets/occupation.json'); 
  }
  getStates(): Observable<any> {
    return this.http.get('assets/states.json'); 
  }
  getSec(): Observable<any> {
    return this.http.get('assets/sector.json'); 
  }
  getDes(): Observable<any> {
    return this.http.get('assets/designation.json'); 
  }
  getEdu(): Observable<any> {
    return this.http.get('assets/education.json'); 
  }
  getLGAs(state:any): Observable<any> {
    return this.http.get('https://mobile.creditclan.com/webapi/v1/states/'+state+'/lgas', httpOptions); 
  }
  
  // getProductDocuments(id: number): Observable<any> {
  //   // get users from api
  //   return this.http.post('https://creditclanapis.creditclan.com/api/v2/options/getProductDocuments', JSON.stringify({ id: id }))
  //     .map((response: Response) => response.json());
  // }
  // getLGAs(token:any, id: number): Observable<any> {
  //   // get users from api
  //   return this.http.post('https://creditclanapis.creditclan.com/api/v2/options/getStateLGAs', JSON.stringify({ id: id, token:token }))
  //     .map((response: Response) => response.json());
  // }
  // getDefaultBank(token: string): Observable<any> {
  //   // get users from api
  //   return this.http.post('https://creditclanapis.creditclan.com/api/v2/options/getDefaultBank', JSON.stringify({ token: token }))
  //     .map((response: Response) => response.json());
  // }
  // getSecurityQuestions(token: string): Observable<any> {
  //   // get users from api
  //   return this.http.post('https://creditclanapis.creditclan.com/api/v2/options/getSecurityQuestions', JSON.stringify({ token: token }))
  //     .map((response: Response) => response.json());
  // } 
  // getOccupation(type: number): Observable<any> {
  //   // get users from api
  //   return this.http.post('https://creditclanapis.creditclan.com/api/v2/options/getOccupation', JSON.stringify({ type: type }))
  //     .map((response: Response) => response.json());
  // }
  // getAllCompanies(token:any): Observable<any> {
  //   // get users from api
  //   return this.http.post('https://creditclanapis.creditclan.com/api/v2/company/allcompanies', JSON.stringify({ token: token }))
  //     .map((response: Response) => response.json());
  // }
  // getCompanies(token:any): Observable<any> {
  //   // get users from api
  //   return this.http.post('https://creditclanapis.creditclan.com/api/v2/company/all', JSON.stringify({ token: token }))
  //     .map((response: Response) => response.json());
  // }
  // getApprovalLevels(token: any): Observable<any> {
  //   // get users from api
  //   return this.http.post('https://creditclanapis.creditclan.com/api/v2/options/getApprovalLevels', JSON.stringify({ token: token }))
  //     .map((response: Response) => response.json());
  // }
  // getFeesLists(id: number): Observable<any> {
  //   // get users from api
  //   return this.http.post('https://creditclanapis.creditclan.com/api/v2/options/getList', JSON.stringify({ id: id }))
  //     .map((response: Response) => response.json());
  // }
  // getLoanOfficers(token: any): Observable<any> {
  //   // get users from api
  //   return this.http.post('https://creditclanapis.creditclan.com/api/v2/options/getLoanOfficers', JSON.stringify({ token: token }))
  //     .map((response: Response) => response.json());
  // }
  // getYears(): Observable<any> {
  //   // get users from api
  //   return this.http.post('https://creditclanapis.creditclan.com/api/v2/options/getYears', JSON.stringify({ }))
  //     .map((response: Response) => response.json());
  // }
  // getDocTypes(): Observable<any> {
  //   // get users from api
  //   return this.http.post('https://creditclanapis.creditclan.com/api/v2/options/getDocTypes', JSON.stringify({ }))
  //     .map((response: Response) => response.json());
  // }
  // getLoanProducts(token: any): Observable<any> {
  //   // get users from api
  //   return this.http.post('https://loanstest.creditclan.com/products/getProducts', JSON.stringify({ token: token }))
  //     .map((response: Response) => response.json());
  // }
  uploadImage(data: object): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({ 'x-api-key': 'OBAUS1aYGx4YQFhWymCXgLK7rXpmoLv3gtj9ufi8N8wcVxCMvP5RVb9Wp5Bzcn0d' })
    };
    return this.http.post('https://mobile.creditclan.com/webapi/v1/upload/image', data, httpOptions);
  }
}
