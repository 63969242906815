import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': 'OBAUS1aYGx4YQFhWymCXgLK7rXpmoLv3gtj9ufi8N8wcVxCMvP5RVb9Wp5Bzcn0d' })
};

@Injectable({
  providedIn: 'root'
})
export class LoansService {

  constructor(private http: HttpClient) { }
  latestmerchants(): Observable<any> {
    return this.http.get('https://wema.creditclan.com/api/v3/summary/latestmerchants');
  }
  latesttrans(request_id: any, start: any, source: any = ''): Observable<any> {
    return this.http.get('https://wema.creditclan.com/api/v3/summary/latesttrans/' + request_id + '/' + start + '/' + source);
  }
  getoutflowwema(page: any): Observable<any> {
    return this.http.get('http://ec2-3-83-252-100.compute-1.amazonaws.com/transactions.php?pagenumber=' + page);
  }
  getsourcewema(account_number: any): Observable<any> {
    return this.http.post('https://apps3.wemabank.com/VirtualAccountAPI/api/v1/Trans/TransQuery', { craccount: account_number });
  }
  wemalogs(start: any, account_number: any): Observable<any> {
    return this.http.post('https://wema.creditclan.com/api/v3/summary/wemalogs', { start: start, account_number: account_number });
  }
  getProvidusTransactions(account_number: any, startDate: any, endDate: any): Observable<any> {
    return this.http.post('https://wema.creditclan.com/api/v3/summary/providuslogs', { account_number: account_number, startDate: startDate, endDate: endDate });
  }
  search(search: any, type: any): Observable<any> {
    if (type == 'account') {
      return this.http.post('https://wema.creditclan.com/api/v3/summary/search', { search: search });
    }
    if (type == 'name') {
      return this.http.post('https://mobile.creditclan.com/api/v3/merchant/search', { search: search }, httpOptions);
    }
    if (type == 'phone') {
      return this.http.post('https://mobile.creditclan.com/api/v3/merchant/search', { search: search }, httpOptions);
    }
  }
  latestwith(request_id: any, start: any): Observable<any> {
    return this.http.get('https://wema.creditclan.com/api/v3/summary/latestwith/' + request_id + '/' + start);
  }
  getBalances(start: any, currentDate: any): Observable<any> {
    return this.http.get(`https://wema.creditclan.com/api/v3/summary/getbalances/${start}?start_date=${currentDate.start_date}&end_date=${currentDate.end_date}`);
  }
  getWemaBalance(): Observable<any> {
    return this.http.get('http://ec2-3-83-252-100.compute-1.amazonaws.com/balance.php');
  }
  getRevenues(request_id: any): Observable<any> {
    return this.http.get('https://wema.creditclan.com/api/v3/summary/getRevenues/' + request_id);
  }
  getAccountsOpened(): Observable<any> {
    return this.http.get('https://wema.creditclan.com/api/v3/summary/accountsopened');
  }
  // getMerchants(start:any): Observable<any> {
  //   return this.http.get('https://wema.creditclan.com/api/v3/summary/getMerchants/'+start);
  // }
  getMerchants(start: any): Observable<any> {
    return this.http.get('https://creditclanapis.creditclan.com/api/v2/services/getStaffMerchants/');
  }
  getAccountNumbers(start: any): Observable<any> {
    return this.http.get('https://sellbackend.creditclan.com/parent/index.php/globalrequest/getMerchantAccountss/' + start);
  }

  getOnboardings(): Observable<any> {
    return this.http.get('https://wema.creditclan.com/api/v3/summary/onboardings');
  }
  getInflows(request_id: any): Observable<any> {
    return this.http.get('https://wema.creditclan.com/api/v3/summary/inflows/' + request_id);
  }
  getOutflows(request_id: any): Observable<any> {
    return this.http.get('https://wema.creditclan.com/api/v3/summary/outflows/' + request_id);
  }

  getCurrentLoans(data: object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/current_loan', data, httpOptions);
  }
  getLoanPortfolio(data: object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/current_loan', data, httpOptions);
  }
  getLoanHistory(data: object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/customer/loan/history', data, httpOptions);
  }
  getLoan(data: object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/loan/details', data, httpOptions);
  }
  apply(data: object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/webapi/v1/loan/cashadvanceapply', data, httpOptions);
  }
  makePaymentForLoan(data: object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/loan/payment/flutterwave', data, httpOptions);
  }
  getProducts(): Observable<any> {
    return this.http.get('https://mobile.creditclan.com/webapi/v1/products', httpOptions);
  }
  chargeCard(payload: object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/loan/charge/flutterwave', payload, httpOptions);
  }
  chargeWallet(payload: object): Observable<any> {
    return this.http.post('https://mobile.creditclan.com/api/v3/loan/charge/wallet', payload, httpOptions);
  }

  getAccounts(): Observable<any> {
    return this.http.get('https://wema.creditclan.com/api/v3/summary/getBalance');
  }
}
