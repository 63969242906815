import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { formatNumber } from 'src/app/helpers';

@Component({
  selector: 'app-transaction-tracking-table',
  templateUrl: './transaction-tracking-table.component.html',
  styleUrls: ['./transaction-tracking-table.component.css']
})
export class TransactionTrackingTableComponent implements OnInit {

  @Input() transaction = {};

  ngOnInit() { }

  formatNumber(number: number | string) {
    return formatNumber(+number);
  }


}
