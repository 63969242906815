import {
  Component,
  Input,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { AuthenticationService } from "../../_services/authentication.service";
import { DataService } from "../../_services/data.service";
import { LoansService } from "../../_services/loans.service";
import { StorageService } from "../../_services/storage.service";
import { TransferService } from "../../_services/transfer.service";
@Component({
  selector: "app-accountnumbers",
  templateUrl: "./accountnumbers.component.html",
  styleUrls: ["./accountnumbers.component.css"],
})
export class AccountnumbersComponent implements OnInit {
  @Input() merchant_id = "";
  user: any;
  currentLoan = [];
  data: any;
  modal = "";
  localTransData = [];
  constructor(
    private storageService: StorageService,
    private dataService: DataService,
    private loansService: LoansService,
    private authService: AuthenticationService, // private dialog: MatDialog
    private transferService: TransferService
  ) {
    this.user = this.storageService.read<any>("user");
  }

  ngOnInit() {
    this.getCurrentLoans(this.merchant_id);
  }
  getCurrentLoans(request_id) {
    //this.loading = true;
    this.loansService.getAccountNumbers(request_id).subscribe(
      (result) => {
        this.data = result.data;
      },
      (err) => {}
    );
  }
  ngOnChanges(changes: SimpleChanges) {
    const request_id: SimpleChange = changes.merchant_id.currentValue;
    if (request_id) {
      this.getCurrentLoans(request_id);
    }
  }
  openTransactionLocalModal() {
    this.modal = "sample";
  }
  openhistory(bank, account_number) {
    this.dataService.openHistory.emit({
      bank: bank,
      account_number: account_number,
    });
  }
  getLocalTransactions(bank) {
    // this.transferService.localTransactions(bank).subscribe(
    //   (result) => {
    //     console.log("localTransactions", result.data);
    //     this.localTransData = result.data;
    //   },
    //   (err) => {
    //     console.log(err);
    //   }
    // );
    // this.openTransactionLocalModal();
  }
}
