
export const getRoutes = () => [
  { path: 'merchants', roles: ['1', '1096', '1097'] },
  { path: 'logs', roles: ['1', '1095', '1096'] },
  { path: 'withdraw', roles: ['1'] },
  { path: 'fundwallet', roles: ['1'] },
  { path: 'pendingtransfers', roles: ['1', '1097'] },
  { path: 'approvedtransfers', roles: ['1', '1097'] },
  { path: 'beneficiaries', roles: ['1'] },
  { path: 'accountbalances', roles: ['1'] },
  { path: 'outflows', roles: ['1'] },
  { path: 'allinflows', roles: ['1'] },
  { path: 'global-report', roles: ['1', '3'] },
  { path: 'staff-tracking', roles: ['1', '1096'] },
  { path: 'commission', roles: ['1'] },
]

export const keysToCheck: string[] = [
  "VFD",
  "access",
  "gtb",
  "kuda",
  "moniepoint",
  "ninepsb",
  "providus",
  "sterling",
  "wema",
  "wema_account",
  "zenith"
];
