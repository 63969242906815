import { Component, OnInit } from "@angular/core";
import { currentDate, formatCurrency } from "../../helpers/index";
import { ApiService } from "src/app/_services/api.service";

@Component({
  selector: "app-repayment-rate",
  templateUrl: "./repayment-rate.component.html",
  styleUrls: ["./repayment-rate.component.css"],
})
export class RepaymentRateComponent implements OnInit {
  selected_option: string = "17036";
  loading: boolean = false;
  start_date: any = currentDate().start_date;
  end_date: any = currentDate().end_date;
  requests: any = [];
  rate: number = 0;
  options: any = [
    {
      name: "VFD",
      value: "17036",
    },
    {
      name: "CDL",
      value: "40490",
    },
  ];

  constructor(private _api: ApiService) {}

  ngOnInit() {
    this.getRequests();
  }

  get items(): any[] {
    return this.requests;
  }

  get repaymentCount(): number {
    return this.items.length;
  }

  get percentage(): string {
    return `${+this.rate.toFixed(2)} %`;
  }

  get disableAction(): boolean {
    return !this.start_date || !this.end_date || !this.selected_option;
  }

  formatCurrency(amount: any) {
    return formatCurrency(amount);
  }

  async getRequests() {
    try {
      if (!this.start_date || !this.end_date || !this.selected_option) return;
      this.loading = true;
      const payload = {
        start: this.start_date,
        end: this.end_date,
        partner: this.selected_option,
      };
      const res = await this._api.getRepaymentRate(payload);
      this.requests = res[0];
      this.rate = res[1];
      this.loading = false;
    } catch (error) {
      this.loading = false;
      console.log(error);
    }
  }
}
