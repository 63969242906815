import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "x-api-key":
      "WE4mwadGYqf0jv1ZkdFv1LNPMpZHuuzoDDiJpQQqaes3PzB7xlYhe8oHbxm6J228",
  }),
};

@Injectable({
  providedIn: "root",
})
export class TransferService {
  constructor(private http: HttpClient) {}

  // getPendingTransactions(start: Number): Observable<any> {
  //   return this.http.post(
  //     "https://mobile.creditclan.com/api/v3/nip/transfers/pending",
  //     { start: start },
  //     httpOptions
  //   );
  // }

  getPendingTransactions(start) {
    return this.http
      .post(
        "https://mobile.creditclan.com/api/v3/nip/transfers/pending",
        { start },
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            "x-api-key":
              "WE4mwadGYqf0jv1ZkdFv1LNPMpZHuuzoDDiJpQQqaes3PzB7xlYhe8oHbxm6J228",
          }),
        }
      )
      .toPromise<any>();
  }
  getApprovedTransactions(start, ) {
    return this.http
      .post(
        "https://mobile.creditclan.com/api/v3/nip/transfers/approved",
        { start },
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            "x-api-key":
              "WE4mwadGYqf0jv1ZkdFv1LNPMpZHuuzoDDiJpQQqaes3PzB7xlYhe8oHbxm6J228",
          }),
        }
      )
      .toPromise<any>();
  }
  getBeneficiaries(start) {
    return this.http
      .post(
        "https://mobile.creditclan.com/api/v3/nip/transfers/beneficiary",
        { start },
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            "x-api-key":
              "WE4mwadGYqf0jv1ZkdFv1LNPMpZHuuzoDDiJpQQqaes3PzB7xlYhe8oHbxm6J228",
          }),
        }
      )
      .toPromise<any>();
  }
  searchApprovedTransactons(start, type) {
    return this.http
      .post(
        "https://mobile.creditclan.com/api/v3/nip/transfers/approved",
        { start, type },
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            "x-api-key":
              "WE4mwadGYqf0jv1ZkdFv1LNPMpZHuuzoDDiJpQQqaes3PzB7xlYhe8oHbxm6J228",
          }),
        }
      )
      .toPromise<any>();
  }
}
