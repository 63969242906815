import { Component, OnInit, Input } from '@angular/core';
import { formatCurrency } from 'src/app/helpers';

@Component({
  selector: 'app-commission-detail-table',
  templateUrl: './commission-detail-table.component.html',
  styleUrls: ['./commission-detail-table.component.css']
})
export class CommissionDetailTableComponent implements OnInit {

  constructor() { }

  @Input() commission_detail: any = {};
  @Input() staff: any = {};

  ngOnInit() {
    console.log(this.commission_detail)
    console.log(this.staff)
  }

  formatCurrency(value) {
    return formatCurrency(+value);
  }

}
