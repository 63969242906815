import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pending-approval-detail-table',
  templateUrl: './pending-approval-detail-table.component.html',
  styleUrls: ['./pending-approval-detail-table.component.css']
})
export class PendingApprovalDetailTableComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
