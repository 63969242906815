import { Component, OnInit, Input, } from '@angular/core';
import { LoansService } from 'src/app/_services/loans.service';
import { ApiService } from 'src/app/_services/api.service';
import { currentDate, formatCurrency } from 'src/app/helpers';

@Component({
  selector: 'app-global-report',
  templateUrl: './global-report.component.html',
  styleUrls: ['./global-report.component.css']
})
export class GlobalReportComponent implements OnInit {

  constructor(
    private _api: ApiService,
    private loansService: LoansService,
  ) { }
  reports: Array<any> = [];
  balances: any;
  merchants: any;
  isLoadingLog: boolean = false;
  isFetchingLog: boolean = false;
  startDate = '';
  endDate = '';
  @Input() start = '0';
  initialDate = currentDate();

  ngOnInit() {
    this.getLogs(this.initialDate, 1);
  }

  getLogs(currentDate, page) {
    // if (page === 1) this.isLoadingLog = true;
    // else this.isFetchingLog = true;
    this.reports = [];
  }

  getMerchantName(merchant_id, merchants) {
    let merchant_name = '';
    merchants.forEach((element) => {

      if (element.id === merchant_id) {
        merchant_name = element.name;
      }
    });
    return merchant_name;
  }

  filterMerchantReport(page) {
    this.getLogs({ start_date: this.startDate, end_date: this.endDate }, page)
  }

  loadMoreMerchantReport(page) {
    const start_date = this.startDate || this.initialDate.start_date
    const end_date = this.endDate || this.initialDate.end_date
    this.getLogs({ start_date, end_date }, page)
  }

  formatCurrency(value) {
    return formatCurrency(value)
  }

}
