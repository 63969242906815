import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { formatCurrency } from 'src/app/helpers';

@Component({
  selector: 'app-commission-table',
  templateUrl: './commission-table.component.html',
  styleUrls: ['./commission-table.component.css']
})
export class CommissionTableComponent implements OnInit {

  constructor() { }

  @Input() staff_reports = [];
  @Output() currentItem = new EventEmitter<any>();
  @Output() sortStaff = new EventEmitter<any>();

  ngOnInit() {
  }

  sortList() {
    this.sortStaff.emit()
  }

  sendItem(staff) {
    this.currentItem.emit({ staff });
  }


  formatCurrency(value) {
    return formatCurrency(+value);
  }

}





