import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IRequestsState, IRequestsStateOnline } from '../models/request';



@Injectable({
  providedIn: 'root'
})
export class StoreService {
  private _requests: IRequestsState = { meta: null, items: null };
  private _requestsOnline: IRequestsStateOnline = { meta: null, items: null };

  public $requests = new BehaviorSubject<IRequestsState>(this._requests);
  public $requestsOnline = new BehaviorSubject<IRequestsStateOnline>(this._requestsOnline);

  public get requests(): any {
    return this._requests;
  }
  public get requestsOnline(): any {
    return this._requestsOnline;
  }

  constructor() { }

  setState(key, value, notify = false) {
    console.log({ key, value, notify });

    this[`_${key}`] = value;
    if (notify) {
      this[`$${key}`].next(this[key]);
    }
  }

  loggedInUser() {
    return JSON.parse(localStorage.getItem('user') || null);
  }

  reset() {
    this._requests = { meta: null, items: null, };
    this.$requests.next(this._requests);
    this._requestsOnline = { meta: null, items: null, };
    this.$requestsOnline.next(this._requestsOnline);
  }
}
