import { Component, OnInit, Input } from '@angular/core';
import { formatNumber } from 'src/app/helpers';



@Component({
  selector: 'app-inflow-summary',
  templateUrl: './inflow-summary.component.html',
  styleUrls: ['./inflow-summary.component.css']
})
export class InflowSummaryComponent implements OnInit {

  constructor() { }
  @Input() inflow_reports = []
  ngOnInit() { }

  formatNumber(value) {
    return formatNumber(+value)
  }

}
