import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/_services/api.service";
import { GeneralService } from "src/app/_services/general.service";
import {
  currentDate,
  previousMonth,
  getToday,
} from "src/app/helpers";


@Component({
  selector: "app-transaction-tracking",
  templateUrl: "./transaction-tracking.component.html",
  styleUrls: ["./transaction-tracking.component.css"],
})
export class TransactionTrackingComponent implements OnInit {
  constructor(private _api: ApiService, private _general: GeneralService) { }
  isLoading: boolean = false;
  isFetching: boolean = false;
  filtered: boolean = false;
  searchText: string;
  searched: boolean = false;
  is_searching: boolean = false;
  isToday: boolean = false;
  item: any = null;
  searchedItem: any = null;
  startDate = "";
  endDate = "";
  initialDate = currentDate();
  today = getToday();
  lastMonth = previousMonth();

  durations: Array<object> = [
    { title: "Duration", value: "duration" },
    { title: "Today", value: "today" },
    { title: "This Month", value: "this month" },
    { title: "Last Month", value: "last month" },
  ];


  public get transaction(): any[] {
    if (this.filtered) return this.searchedItem;
    else return this.item;
  }

  ngOnInit() {
    this.getTransaction(this.initialDate, 1);
  }

  async getTransaction(payload: any, page: any) {
    try {
      if (page === 1) this.isLoading = true;
      else this.isFetching = true;
      const { data } = await this._api.getTransaction(payload);
      this.filtered === true ? this.searchedItem = data : this.item = data;
      this.isLoading = false;
      this.isFetching = false;
    } catch (error) {
      this.isLoading = false;
      this.isFetching = false;
      console.log(error);
    }
  }

  filterTransaction({ start_date, end_date, page }) {
    this.filtered = true;
    this.startDate = start_date;
    this.endDate = end_date;
    this.getTransaction({ start_date, end_date }, page);
  }

  filterByDuration({ value, page }) {
    if (value === "duration") return;
    this.filtered = true;
    const date =
      value === "today"
        ? this.today
        : value === "this month"
          ? this.initialDate
          : this.lastMonth;
    this.getTransaction(date, page);
  }

}


