import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/_services/api.service';
import { formatCurrency } from 'src/app/helpers';

interface TopupRecord {
  id: number;
  amount: string;
  extra: null | any;
  type: null | any;
  createdAt: string;
  updatedAt: string;
}

@Component({
  selector: 'app-wallet-balance',
  templateUrl: './wallet-balance.component.html',
  styleUrls: ['./wallet-balance.component.scss']
})
export class WalletBalanceComponent implements OnInit {

  is_reloading: boolean = false;
  topup_history: TopupRecord[] = [];
  filtered_topup_history: TopupRecord[] = [];
  is_loading: boolean = false;
  is_loading_buy_power: boolean = false;
  is_topingup: boolean = false;
  filtered: boolean = false;
  filtering: boolean = false;
  buySimple_balance: any = 0;
  flutter_balance: any = 0;
  buyPower_balance: any = 0;
  filtered_buySimple_balance: any = 0;
  filtered_flutter_balance: any = 0;
  currentDayInflow: any = 0;
  currentDayWithdrawal: any = 0;
  startDate: string = '';
  endDate: string = '';
  topup_amount: string = '';
  topupDate: string = '';
  modals = {
    view_balance_data: '',
  };
  selected_source: string = '0';
  wallet_sources = [
    { name: 'Source', value: '0' },
    { name: 'Flutter wave', value: '1' },
    { name: 'Monefy', value: '2' },
    { name: 'Buy power', value: '3' },
  ]


  public get items(): any[] {
    if (this.filtered) return this.filtered_topup_history.slice(0, 10);
    else return this.topup_history.slice(0, 10);
  }
  public get itemCount() {
    return this.items.length;

  }
  public get buySimpleWalletBalance(): any {
    if (this.filtered) {
      return this.filtered_buySimple_balance
    }
    else return this.buySimple_balance
  }
  public get flutterWalletBalance(): any {
    if (this.filtered) {
      return this.filtered_flutter_balance
    }
    else return this.flutter_balance
  }


  constructor(
    private _api: ApiService
  ) { }



  ngOnInit() {
    this.getWalletData();
  }

  // setBalance(key: string, data: any) {
  //   this.wallets = this.wallets.map(a => a.key === key ? ({ ...a, value: data }) : a)
  // }

  async getWalletData() {
    try {
      this.is_reloading = true;
      const { status, data, buySimply_balance, fluter_balance, buy_power_balance } = await this._api.getMainWalletData()
      this.buySimple_balance = buySimply_balance
      this.flutter_balance = fluter_balance
      this.buyPower_balance = buy_power_balance;
      this.topup_history = this.sortHistory(data)
      this.is_reloading = false;
    } catch (error) {
      this.is_reloading = false;
      console.log(error);
    }
  }

  async topUpWallet() {
    try {
      if (+this.selected_source === 0) return;
      this.is_topingup = true;
      const payload = { amount: this.topup_amount, date: this.topupDate, type: this.selected_source }
      const { status, data } = await this._api.topUpMainWalletData(payload)
      this.topup_history = this.sortHistory([data, ...this.topup_history])
      this.is_topingup = false;
      this.topup_amount = '';
      this.topupDate = '';
    } catch (error) {
      this.is_topingup = false;
      console.log(error);
    }
  }

  async filterWallet() {
    try {
      this.filtered = true;
      this.filtering = true;
      const { status, data, buySimply_balance, fluter_balance } = await this._api.filterMainWalletData({ startDate: this.startDate, endDate: this.endDate })
      this.filtered_topup_history = this.sortHistory(data);
      this.filtered_buySimple_balance = buySimply_balance
      this.filtered_flutter_balance = fluter_balance
      this.filtering = false;
    } catch (error) {
      this.filtering = false;
      console.log(error);
    }
  }

  reset() {
    this.filtered = false;
    this.startDate = '';
    this.endDate = '';
  }

  formatCurrency(currency: any) {
    return formatCurrency(+currency)
  }


  sortHistory(data): any {
    return data.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  }

}
