import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { StorageService } from 'src/app/_services/storage.service';

import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-onboard',
  templateUrl: './onboard.component.html',
  styleUrls: ['./onboard.component.css']
})
export class OnboardComponent implements OnInit {

  resendWelcomeEmail = false;
  //create a model for the register form
  login_form = {
    profile: {

      'email': '',
      'password': '',
      'full_name': '',
      'is_individual': '',
      'phone': ''

    }

  }
  errorMessage = '';
  successMessage = '';
  loading = false;

  constructor(public router: Router, private storageService: StorageService, private authService: AuthenticationService) { }

  ngOnInit() {
    this.authService.logout();
  }
  tryRegister(value) {
    this.loading = true;
    this.authService.register(this.login_form)
      .subscribe(result => {
        this.loading = false;
        localStorage.removeItem('user');
        if (result.status == true) {
          this.authService.login(value)
            .subscribe(result => {
              localStorage.removeItem('user');
              if (result.status == true) {
                localStorage.setItem('user', JSON.stringify(result));
                this.router.navigate(['/dashboard/welcome']);
              } else {
                Swal.fire({
                  title: 'Ooops',
                  text: result.message,
                  icon: 'error'
                });
              }


            }, err => {
            });
        } else {
          Swal.fire({
            title: 'Ooops',
            text: result.message,
            icon: 'error'
          });
        }


      }, err => {
      });
  }
}

