import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgotComponent } from './forgot/forgot.component';
import { LoginComponent } from './login/login.component';
import { OnboardComponent } from './onboard/onboard.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { AuthGuard } from './_guards/auth.guard';


const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: '../app/dashboard/dashboard.module#DashboardModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'loans',
    loadChildren: '../app/loans/loans.module#LoansModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'savings',
    loadChildren: '../app/savings/savings.module#SavingsModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'investments',
    loadChildren: '../app/investments/investments.module#InvestmentsModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    loadChildren: '../app/profile/profile.module#ProfileModule',
    data: {
      preload: true
    },
    canActivate: [AuthGuard]
  },
  { path: 'login', component: LoginComponent },
  { path: 'forgot', component: ForgotComponent },
  { path: 'signup', component: OnboardComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', component: PagenotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
