import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/_services/api.service';
import { GeneralService } from 'src/app/_services/general.service';
import { currentDate, getToday } from 'src/app/helpers';

@Component({
  selector: 'app-repayment-through-wallet',
  templateUrl: './repayment-through-wallet.component.html',
  styleUrls: ['./repayment-through-wallet.component.css']
})
export class RepaymentThroughWalletComponent implements OnInit {

  isLoading: boolean = false;
  isFetching: boolean = false;
  searched: boolean = false;
  searchedRequests: any[] = [];
  searchText: string;
  is_searching: boolean = false;
  filtered: boolean = false;
  isToday: boolean = false;
  requests: Array<any> = [];
  startDate = '';
  endDate = '';
  initialDate = currentDate();
  today = getToday();
  detail_date = currentDate();
  pagination = {
    cursor: 0,
    end: false,
    limit: 50,
    page: 1,
  };
  durations: Array<object> = [
    { title: 'Duration', value: 'duration' },
    { title: 'Today', value: 'today' },
    { title: 'This Month', value: 'this month' }
  ];


  constructor(
    private _api: ApiService,
    private _general: GeneralService,
  ) { }

  ngOnInit() {
    this.getRequests(this.initialDate, 1)

  }

  public get items(): any[] {
    if (this.searchText) return this.searchedRequests;
    else return this.requests;
  }

  async getRequests(payload, page) {
    try {
      if (page === 1) this.isLoading = true;
      else this.isFetching = true;
      const { data } = await this._api.getRepaymentThroughWallet(payload);
      this.requests = data;
      this.isLoading = false;
      this.isFetching = false;
    } catch (error) {
      this.isLoading = false;
      this.isFetching = false;
      console.log(error)
    }
  }

  filterRequest({ start_date, end_date, page }) {
    this.filtered = true;
    this.startDate = start_date;
    this.endDate = end_date;
    this.detail_date = { start_date, end_date }
    this.getRequests({ start_date, end_date }, page)
  }

  filterByDuration({ value, page }) {
    if (value === 'duration') return;
    this.filtered = true;
    const date = value === 'today' ? this.today : this.initialDate
    this.detail_date = date;
    this.isToday = value === 'today' ? true : false;
    this.getRequests(date, page)
  }

  searchRequest() {
    this.searched = true;
    const searchTextLower = this.searchText.toLowerCase();
    this.searchedRequests = this.requests.filter((data) => {
      const senderAccountNumber = data.sender_account_number ? data.sender_account_number.toLowerCase() : '';
      const merchantId = data.merchant_id ? data.merchant_id.toLowerCase() : '';

      return senderAccountNumber.includes(searchTextLower) || merchantId.includes(searchTextLower);
    });

  }

}
