import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/_services/api.service";
import { StoreService } from "src/app/_services/store.service";
import { getSum } from "src/app/helpers";

@Component({
  selector: "app-pending-approvals",
  templateUrl: "./pending-approvals.component.html",
  styleUrls: ["./pending-approvals.component.css"],
})
export class PendingApprovalsComponent implements OnInit {
  is_approving: boolean = false;
  is_loading: boolean = false;
  loading_approval_detail: boolean = false;
  approve_started: boolean = false;
  current_approval_id = null;
  response_message: string = "";
  pending_approvals: Array<any> = [];
  approval_items: Array<any> = [];
  checkedItems: Array<any> = [];
  modals = {
    confirm: "",
    preview_approval: "",
  };

  constructor(private _api: ApiService, private store: StoreService) {}

  get totalSum() {
    return getSum(this.approval_items, "amount");
  }

  ngOnInit() {
    this.getPendingApprovals();
  }

  async getPendingApprovals(): Promise<void> {
    try {
      this.is_loading = true;
      const user = this.store.loggedInUser();
      const userId = user ? user.account_id : "";
      const { data } = await this._api.getPendingApprovals({
        account_id: userId,
      });
      console.log(data);
      this.pending_approvals = data;
      this.is_loading = false;
    } catch (error) {
      this.is_loading = false;
      console.log(error);
    }
  }

  // async getItemId(Id): Promise<void> {
  // this.current_approval_id = Id;
  // this.modals.confirm = 'open';
  // this.approve_started = true;
  // }

  async getApproveId({ approval_id, disbursement_id }): Promise<void> {
    try {
      this.current_approval_id = approval_id;
      this.loading_approval_detail = true;
      this.modals.preview_approval = "open";
      const { data } = await this._api.getDisbursementDetail({
        disbursement_id: disbursement_id,
      });
      this.approval_items = data;
      this.loading_approval_detail = false;
    } catch (error) {
      this.loading_approval_detail = false;
      console.log(error);
    }
  }

  async approveDisbursement(): Promise<void> {
    try {
      this.is_approving = true;
      this.approve_started = true;
      const { data, status } = await this._api.approveDisbursement({
        pending_approval_id: this.current_approval_id,
        data: this.checkedItems,
      });
      if (status) {
        this.is_approving = false;
        this.response_message = "success";
        setTimeout(() => {
          this.response_message = "";
          this.modals.confirm = "";
        }, 2000);
      } else {
        this.is_approving = false;
        this.response_message = "error";
      }
    } catch (error) {
      this.is_approving = false;
      this.response_message = "error";
      console.log(error);
    }
  }

  getCheckedList(checkLists: any) {
    this.checkedItems = checkLists.data;
    console.log(checkLists);
  }
}
