import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "x-api-key":
      "WE4mwadGYqf0jv1ZkdFv1LNPMpZHuuzoDDiJpQQqaes3PzB7xlYhe8oHbxm6J228",
  }),
};

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private http: HttpClient) { }

  private sellbackend_url =
    "https://sellbackend.creditclan.com/merchantclan/public/index.php/api";
  private wema_url = "https://wema.creditclan.com/api";
  private mobile_url = "https://mobile.creditclan.com/api";
  private wasapnodeserver = "https://lendnode.creditclan.com/gateway";
  private creditclan_base_url = "https://creditclanapis.creditclan.com";

  // private lendnode = 'https://lendnode.creditclan.com';

  getReferralOrders(payload: any) {
    return this.http
      .post(`${this.sellbackend_url}/staff/order_referal`, payload)
      .toPromise<any>();
  }

  getReferralOrdersDetails(payload: any) {
    return this.http
      .post(`${this.sellbackend_url}/staff/order_referal_details`, payload)
      .toPromise<any>();
  }

  confirmReferralPayments(payload: any) {
    return this.http
      .post(`${this.sellbackend_url}/staff/confirm_order_referal`, payload)
      .toPromise<any>();
  }

  getStaffReport(payload: any) {
    return this.http
      .post(`${this.sellbackend_url}/affiliates/report`, payload)
      .toPromise<any>();
  }

  getTransaction({ start_date, end_date }) {
    return this.http
      .get(`${this.wema_url}/v3/summary/periodreport/${start_date}/${end_date}`)
      .toPromise<any>();
  }

  getMerchantList(
    phone: string,
    is_today: boolean,
    startDate: string,
    endDate: string
  ) {
    const identifier = is_today ? "today" : "month";
    return this.http
      .get(
        `${this.sellbackend_url}/agent/report?filter=${identifier}&agent_phone=${phone}&start_date=${startDate}&end_date=${endDate}`
      )
      .toPromise<any>();
  }

  getMerchantListDetail(payload: any) {
    return this.http
      .post(`${this.wema_url}/v3/summary/transsummary`, payload)
      .toPromise<any>();
  }

  getMerchantRecords(payload) {
    return this.http
      .post(`${this.wema_url}/v3/summary/merchant_reports`, payload)
      .toPromise<any>();
  }
  getInflowSummary(start: string, { start_date, end_date }: any) {
    return this.http
      .get(
        `${this.wema_url}/v3/summary/getbankbalances/${start}?start_date=${start_date}&end_date=${end_date}`
      )
      .toPromise<any>();
  }

  getOutflowSummary(start: string, { start_date, end_date }: any) {
    return this.http
      .get(
        `${this.mobile_url}/v3/nip/transfers/summary?start=${start}&start_date=${start_date}&end_date=${end_date}`,
        httpOptions
      )
      .toPromise<any>();
  }

  sendBroadcastMessage(payload: any) {
    return this.http
      .post(`${this.sellbackend_url}/message/broadcast`, payload)
      .toPromise<any>();
  }
  getTeamLead(phone: string) {
    return this.http
      .get(`${this.sellbackend_url}/agent?phone=${phone}`)
      .toPromise<any>();
  }

  getTotalInflow(merchant_id: any) {
    return this.http
      .get(`${this.wema_url}/v3/summary/inflows/${merchant_id}`)
      .toPromise<any>();
  }
  getTotalOutflow(merchant_id: any) {
    return this.http
      .get(`${this.wema_url}/v3/summary/outflows/${merchant_id}`)
      .toPromise<any>();
  }

  updateStaffInfo(payload: any, agent_id: any) {
    return this.http
      .post(`${this.sellbackend_url}/staff/${agent_id}/profile`, payload)
      .toPromise<any>();
  }

  getCommission() {
    return this.http
      .get(`${this.sellbackend_url}/staff/payroll`)
      .toPromise<any>();
  }

  getCommissionReport() {
    return this.http
      .get(`${this.sellbackend_url}/staff/payroll/history`)
      .toPromise<any>();
  }
  getMainWalletData() {
    return this.http.get(`${this.wasapnodeserver}/get_amount`).toPromise<any>();
  }
  // getBuyPowerWalletBalance() {
  //   return this.http.get(`${this.lendnode}/buypower/buypower/accbalance`).toPromise<any>()
  // }
  topUpMainWalletData(payload: any) {
    return this.http
      .post(`${this.wasapnodeserver}/save_amount`, payload)
      .toPromise<any>();
  }
  filterMainWalletData(payload: any) {
    return this.http
      .post(`${this.wasapnodeserver}/filter_amount`, payload)
      .toPromise<any>();
  }

  getChartRecord(bank) {
    if (bank.toLowerCase() === "all") {
      return this.http
        .get(`${this.wema_url}/v3/summary/chartaccounts`)
        .toPromise<any>();
    } else {
      return this.http
        .get(`${this.wema_url}/v3/summary/chartaccounts?bank=${bank}`)
        .toPromise<any>();
    }
  }

  createUser(payload: any) {
    return this.http
      .post(`${this.mobile_url}/v3/onboard/user`, payload, httpOptions)
      .toPromise<any>();
  }

  searchMerchant(payload: any) {
    return this.http
      .post(`${this.sellbackend_url}/search/merchants`, payload)
      .toPromise<any>();
  }

  getStaffReportByLoaBooked(payload: any) {
    return this.http
      .post(`${this.sellbackend_url}/affiliates/report`, payload)
      .toPromise<any>();
  }

  getRequests({ agent_id, status, start_date, end_date }: any) {
    return this.http
      .get(
        `${this.sellbackend_url}/merchant/loan/details?status=${status}&agent_id=${agent_id}&start_date=${start_date}&end_date=${end_date}`
      )
      .toPromise<any>();
  }

  getCommissionByDate({ staff_id, date }: any) {
    return this.http
      .post(`${this.sellbackend_url}/agent/${staff_id}/salary`, date)
      .toPromise<any>();
  }

  getAgentProspects({ next_cursor, agent_id, page, limit }: any) {
    if (page === 1) {
      return this.http
        .get(
          `${this.sellbackend_url}/merchant/loan/prospects?count=${limit}&agent_id=${agent_id}`
        )
        .toPromise<any>();
    } else {
      return this.http
        .get(
          `${this.sellbackend_url}/merchant/loan/prospects?count=${limit}&agent_id=${agent_id}&cursor=${next_cursor}`
        )
        .toPromise<any>();
    }
  }

  getWallets() {
    return this.http.get(`${this.wema_url}/v3/wallet/wallets`).toPromise<any>();
  }

  createWallet(payload: object) {
    return this.http
      .post(`${this.wema_url}/v3/wallet/add_wallet`, payload)
      .toPromise<any>();
  }

  getDisbursements() {
    return this.http
      .get(`${this.wema_url}/v3/wallet/disbursements`)
      .toPromise<any>();
  }

  createDisbursement(payload: object) {
    return this.http
      .post(`${this.wema_url}/v3/wallet/add_disbursement`, payload)
      .toPromise<any>();
  }

  getDisbursementDetail(payload: any) {
    return this.http
      .post(`${this.wema_url}/v3/wallet/disbursement`, payload)
      .toPromise<any>();
  }

  initialtApproveDisbursement(payload: any) {
    return this.http
      .post(`${this.wema_url}/v3/wallet/initiate_approval`, payload)
      .toPromise<any>();
  }

  getPendingApprovals(payload: any) {
    return this.http
      .post(`${this.wema_url}/v3/wallet/approvals`, payload)
      .toPromise<any>();
  }
  getDisbursementDetailPending(payload: any) {
    return this.http
      .post(`${this.wema_url}/v3/wallet/disbursement_unapproved`, payload)
      .toPromise<any>();
  }
  getDisbursementDetailApproved(payload: any) {
    return this.http
      .post(`${this.wema_url}/v3/wallet/disbursement_approved`, payload)
      .toPromise<any>();
  }
  deleteDisbursement(payload: any) {
    return this.http
      .post(`${this.wema_url}/v3/wallet/delete_disbursement`, payload)
      .toPromise<any>();
  }
  getDisburseApprovals({ start_date, end_date }) {
    return this.http
      .get(
        `${this.wema_url}/v3/wallet/approved_disbursements?start_date=${start_date}&end_date=${end_date}`
      )
      .toPromise<any>();
  }
  approveDisbursement(payload: any) {
    return this.http
      .post(`${this.wema_url}/v3/wallet/approve`, payload)
      .toPromise<any>();
  }
  getRepaymentThroughWallet(payload: any) {
    return this.http
      .post(`${this.wema_url}/v3/summary/repayments_in_wallet`, payload)
      .toPromise<any>();
  }
  getReverserThroughWallet(payload: any) {
    return this.http
      .post(`${this.wema_url}/v3/summary/reversals_in_wallet`, payload)
      .toPromise<any>();
  }
  getDisbursementApproved(payload: any) {
    // return this.http.post(`${this.wema_url}/v3/wallet/disbursements`, payload).toPromise<any>()
    return this.http
      .post(`${this.wema_url}/v3/wallet/disbursement`, payload)
      .toPromise<any>();
  }
  getInflowDetail(payload: any) {
    return this.http
      .post(`${this.wema_url}/v3/summary/inflow_details`, payload)
      .toPromise<any>();
  }
  getAccountTransactions(url: any, account: any, type: Number) {
    if (type == 2 || type == 5) {
      return this.http.get(`${url}`, httpOptions).toPromise<any>();
    } else {
      let paylod =
        type == 1
          ? { craccount: account }
          : type == 3
            ? { acc_num: account }
            : { account_number: account };

      return this.http.post(`${url}`, paylod).toPromise<any>();
    }
  }

  getCommissionByDateStaff(payload: any) {
    return this.http
      .post(
        `https://creditclanapis.creditclan.com/api/v2/services/preparecommisionpayment`,
        payload
      )
      .toPromise<any>();
  }

  getStoreOrders(page) {
    return this.http
      .get(`${this.sellbackend_url}/backend_merchant_order/${page}`)
      .toPromise<any>();
  }
  getOnlineOrders(page) {
    return this.http
      .get(`${this.sellbackend_url}/backend_external_orders/${page}`)
      .toPromise<any>();
  }
  getBanksInflowsSummary(payload) {
    return this.http
      .post(`${this.wema_url}/v3/summary/inflow_details_summary`, payload)
      .toPromise<any>();
  }
  getRepaymentRate = async (payload) => {
    return this.http
      .post(
        `${this.creditclan_base_url}/api/v2/services/repaymentrate`,
        payload
      )
      .toPromise<any>();
  }

  getBVNDetail(phone: string) {
    return this.http
      .get(`${this.wasapnodeserver}/verify_user_bvn/${phone}`)
      .toPromise<any>();
  }
}
