import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
// import { ApiService } from 'src/app/services/api.service';
import { debounceTime, take } from 'rxjs/operators';
import { fromEvent, Subscription } from 'rxjs';
// import { fromEvent, lastValueFrom, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/_services/api.service';
import { StoreService } from 'src/app/_services/store.service';
import { IRequest } from 'src/app/models/request';
// import { GeneralService } from 'src/app/services/general.service';
// import { StoreService } from 'src/app/services/store.service';
// import { IRequest } from 'src/app/models/requests';
// import { AuthService } from 'src/app/services/auth.service';
// import { AnalyticsService } from '../../services/analytics.service';
import { formatCurrency } from '../../helpers/index';

@Component({
  selector: 'app-store-orders',
  templateUrl: './store-orders.component.html',
  styleUrls: ['./store-orders.component.scss']
})
export class StoreOrdersComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('search', { static: false }) input: ElementRef;

  subscriptions: Subscription[] = [];
  requests: IRequest[] = [];
  searched = {
    data: [],
    meta: null
  };
  loading = false;
  fetching = false;
  modal = '';
  currentRequest = null;
  mobile = window.innerWidth < 768;
  meta: any;
  avater: string = 'https://datasym.co.uk/wp-content/uploads/2017/09/blank-grey.png';
  statusCount: any = {};
  filter = {
    data: [],
    meta: null,
    params: null
  };
  filterOptions = {
    creditOrOutright: 'all',
    confirmationStatus: 'none',
    deliveryStatus: 'none',
    payment: 'none'
  };

  // private _api: ApiService,
  //   private activatedRoute: ActivatedRoute,
  //   private router: Router,
  //   public _general: GeneralService,
  //   public _auth: AuthService,
  //   public _store: StoreService,
  //   private _analytics: AnalyticsService,

  constructor(
    private _api: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public _store: StoreService,
  ) { }

  public get filtered(): boolean {
    const { confirmationStatus, deliveryStatus, creditOrOutright, payment } = this.filterOptions;
    return !(
      creditOrOutright === 'all' && confirmationStatus === 'none' && deliveryStatus === 'none' && payment === 'none'
    );
  }

  public get items(): any[] {
    if (this.searched.meta) {
      return this.searched.data;
    }
    else if (this.filter.meta) {
      return this.filter.data;
    }
    else {
      return this.requests;
    }
  }
  public get itemCount(): number {
    return this.items.length;
  }

  ngOnInit() {
    // this.fetchRequests(1);
    // this._analytics.track('Visited orders page');
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.id) {
        const request = this.requests.find(r => r.id.toString() === params.id.toString());
        if (request) {
          this.currentRequest = request;
          this.modal = 'view';
        }

        // else {
        //   this.fetchSingleRequest(params.id);
        // }
      }
    });
    if (!this._store.requests.items) this.fetchOrders(1);

    this.subscriptions.push(
      this._store.$requests.subscribe({
        next: (res) => {
          console.log(res);
          if (!res.items) return;
          this.requests = res.items;
          this.meta = res.meta;
        }
      })
    );
  }

  fornmatCurrency(currency: string) {
    return formatCurrency(+currency);
  }

  ngAfterViewInit() {
    this.subscriptions.push(
      fromEvent(this.input.nativeElement, 'input').pipe(debounceTime(1000)).subscribe((e: any) => {
        const query = e.target.value;
        if (query) {
          this.search(query);
        } else {
          this.searched = { data: [], meta: null };
        }
      })
    );
  }

  search(query, page_number = 1) {
    // this[page_number === 1 ? 'loading' : 'fetching'] = true;
    // this._api.searchOrdersOrRepaymentOrSales({
    //   search: query, page_number: `${page_number}`, orders: true
    // }).subscribe(({ data, ...meta }: any) => {
    //   this[page_number === 1 ? 'loading' : 'fetching'] = false;
    //   if (page_number === 1) {
    //     this.searched.data = data;
    //   } else {
    //     this.searched.data = [...this.searched.data, ...data];
    //   }
    //   this.searched.meta = meta;
    // }, (err) => {
    //   console.log({ err });
    // });
  }

  // async fetchSingleRequest(id) {
  // this.currentRequest = await lastValueFrom<any>(this._api.getSingleRequest(id));
  // this.modal = 'view';
  // }

  fetchOrders(page_number = 1) {
    this.fetchStoreOrders(page_number);
  }

  async fetchStoreOrders(page_number) {
    try {
      this[page_number === 1 ? 'loading' : 'fetching'] = true;
      // const count = this.itemCount;
      const { data, ...meta } = await this._api.getStoreOrders(page_number);
      this.statusCount = this.countStatusOrders(data)
      if (page_number === 1) {
        this._store.setState('requests', { ...this._store.requests, items: data, meta }, true);
      } else {
        this.statusCount = this.countStatusOrders([...this.requests, ...data])
        this._store.setState('requests', {
          ...this._store.requests, items: [...this.requests, ...data], meta,
        }, true);




        // this[page_number === 1 ? 'loading' : 'fetching'] = false;
        // if (count === this.itemCount && this.meta.next) {
        //   this.fetchRequests(this.meta.next);
        // }
      }
      console.log(this._store.requests);
      this[page_number === 1 ? 'loading' : 'fetching'] = false;
    } catch (error) {
      this[page_number === 1 ? 'loading' : 'fetching'] = false;
      console.log(error);
    }
  }

  async viewRequest(request, view = 'details') {
    await this.router.navigate([`dashboard/order`], { queryParams: { id: request.id, view } });
  }

  async closeViewRequest(refresh) {
    if (refresh) {
      this.fetchOrders();
    }
    this.modal = '';
    this.currentRequest = null;
    await this.router.navigate([`dashboard/order`]);
  }

  updateCurrentRequest(request) {
    // this.currentRequest = request;
    // const items = this._store.requests.items.map(r => r.id === request.id ? request : r);
    // this._store.setState('requests', { ...this._store.requests, items }, true);
  }

  loadMore() {
    // if (this.searched.data.length) {
    //   if (this.searched.meta.next) this.search(this.input.nativeElement.value, this.searched.meta.next);
    //   return;
    // } else if (this.filter.data.length) {
    //   if (this.filter.meta.next) this.filterOrders(this.filter.meta.next);
    //   return;
    // } else {
    //   this.fetchRequests(this.meta.next);
    // }
  }

  launchModalIfProfileComplete(modal) {
    // if (!this._auth.isProfileComplete) return this._general.showPendingSetup();
    // this.modal = modal;
  }

  handleFilterChange() {
    this.modal = '';
    const { creditOrOutright, deliveryStatus, confirmationStatus, payment } = this.filterOptions;
    const params = {} as any;

    if (creditOrOutright === 'all') params.on_credit = 2;
    else if (creditOrOutright === 'credit') params.on_credit = 1;
    else if (creditOrOutright === 'outright') params.on_credit = 0;

    if (deliveryStatus === 'delivered') params.delivered = 1;
    else if (deliveryStatus === 'shipping') params.shipping = 1;

    if (confirmationStatus === 'rejected') params.rejected = 1;

    if (payment === 'paid') params.paid = 1;
    else if (payment === 'upfront_paid') params.upfront_paid = 1;
    else if (payment === 'pending') params.upfront_paid = 0;

    this.filter.params = params;
    this.filterOrders();
  }

  private async filterOrders(page_number = 1) {
    // const payload = { ...this.filter.params, page_number };
    // this[page_number === 1 ? 'loading' : 'fetching'] = true;
    // try {
    //   const { data, ...meta } = await lastValueFrom<any>(this._api.filterOrders(payload));
    //   if (page_number === 1) {
    //     this.filter.data = data;
    //   } else {
    //     this.filter.data = [...this.filter.data, ...data];
    //   }
    //   this.filter.meta = meta;
    // } catch (e) {
    //   console.dir(e);
    // }
    // this[page_number === 1 ? 'loading' : 'fetching'] = false;
  }

  resetFilter() {
    this.modal = '';
    this.filter.data = [];
    this.filter.meta = null;
    this.filter.params = null;
    this.filterOptions = {
      creditOrOutright: 'all',
      confirmationStatus: 'none',
      deliveryStatus: 'none',
      payment: 'none'
    };
  }

  formatStatus(status) {
    if (status) {
      return status.toLowerCase();
    } else {
      return status
    }
  }

  getTotalAmount(amount, qty) {
    const total = (+amount) * (+qty);
    return formatCurrency(total);
  }

  countStatusOrders(arr) {
    let statusCount = {
      pending: 0,
      shipped: 0,
      delivered: 0
    };

    arr.forEach(item => {
      const status = item.status.toLowerCase();

      if (status === 'pending') {
        statusCount.pending++;
      } else if (status === 'shipped') {
        statusCount.shipped++;
      } else if (status === 'delivered') {
        statusCount.delivered++;
      }
    });

    return statusCount;
  }


  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }


}
